import { action, asyncAction } from '@play-co/replicant';
import { createActions } from '../../createActions';
import {
  getBadges,
  getBaseEnergy,
  getCombinedUnclaimedRewards,
  getDailyCode,
  getHasAServerDayElapsed,
} from './game.getters';
import {
  getBoosterLevel,
  getBoosterPrice,
  getBuffInfo,
  getConstrainedTaps,
  getEnergyLimit,
  getTaps,
  getTimeLeft,
} from './game.getters';

import {
  acquireBooster,
  consumeTaps,
  handleFirstEntry,
  refreshEnergy,
  addEarningReward,
  claimPowerUpReward,
  initForRequireMoreFriendsFromLast,
  buyPowerUp,
  clearDailyConditions,
  handleTutorialUpdate,
  incrementScore,
  addVideoQuestReward,
  resetDailyCode,
  handleDailyRewards,
  updateQuest,
  syncTgStarsTxAndCredits,
  syncModerationStatus,
  clearOutdatedUserMemeGifts,
} from './game.modifiers';
import { Booster } from './ruleset/boosters';
import {
  Buff,
  FREE_ENERGY_RECHARGE_COOLDOWN,
  FREE_ROCKETMAN_USES,
} from './ruleset/buffs';
import { DAILY_CODE_PRIZE } from './ruleset/dailyRewards';

import { autoBotConfig } from './ruleset/autobot';
import { EarningKey } from './ruleset/earnings';
import { ABTestBucket, ABTestID, tests } from '../../ruleset';
import { TelegramUser } from '../chatbot/chatbot.schema';
import {
  migratePowerUps,
  roundBalanceScoreAndTaps,
  initGiftCards,
  migrateQuests,
  migrateToNextSeason,
  migratePowerUps1,
  fixFriendSubState,
} from './migration.modifiers';
import {
  getActivePowerUpById,
  getCanReceiveDiscount,
  getGiftDiscount,
  getPowerUps,
  hasReachedMaxDiscount,
  hasReceivedFromUserToday,
} from '../powerups/getters';
import { manuallyAssignAbTests } from './abtest.modifiers';
import { getChatMember } from '../chatbot/chatbot.api';
import { BadgeItem, PowerUpCardType } from '../powerups/types';
import { stage } from './game.config';
import { ExpectedError, isExpectedError } from '../../types';
import { getQuestsReadyToClaim } from '../quests/getters';
import { SEASON } from './ruleset/league';
import { runSoftMigrations } from '../../soft_migrations/softMigrator';
import { DAILY_CHECKIN_CONTRACT_REWARD, ONE_DAY_MS } from './ruleset/contract';
import { tmgRefillTicketsAndWipeSessionCache } from '../offchainTrading/offchainTrading.modifiers';

export const gameActions = createActions({
  /** Called automatically before every login. */
  onLogin: asyncAction(async (state, _: void, api) => {
    if (stage !== 'prod' && stage !== undefined) {
      if (
        state.ruleset.abTests[tests.ACCESS_WHITELIST]?.bucketId !== 'granted'
      ) {
        throw new Error(
          `Access not granted to player ${state.id} in environment ${stage}`,
        );
      }
    }
    // Always keep this one on the top; Ideally in the future we can remove the ones below
    await runSoftMigrations(state, api);

    await migratePowerUps(state, api);
    await migratePowerUps1(state);
    // Make sure this is called after 'runSoftMigrations' or the user will get double migrated
    await migrateToNextSeason(state);

    syncTgStarsTxAndCredits(state, api);

    manuallyAssignAbTests(state, api);

    // always update the score and balance to be integers
    roundBalanceScoreAndTaps(state);

    migrateQuests(state);

    await fixFriendSubState(state, api);

    /*
    entryFinal scans through powerUps for getBalance, which happens before
    startSession is called, so this needs to happen before then.
    */
    initGiftCards(state, api.date.now());

    try {
      // updates token status from dynamodb to opensearch if they are different
      await syncModerationStatus(state, api);
    } catch (e) {
      console.error('Failed to sync moderation status', e);
    }
  }),
  startSession: action(
    (
      state,
      payload: {
        profile: { name?: string; photo?: string };
      },
      api,
    ) => {
      Object.assign(state.profile, payload.profile);

      // restore energy
      const now = api.date.now();
      refreshEnergy(state, now);

      tmgRefillTicketsAndWipeSessionCache(state, api);

      state.session_taps = 0;
      state.session_start_time = now;

      // Called once the server has completed the day GMT-0
      if (getHasAServerDayElapsed(state, now)) {
        state.powerUps.daily.timestamp = now;
        state.powerUps.daily.power_ups = [];
        clearDailyConditions(state);
      }

      clearOutdatedUserMemeGifts(state, now);

      // Reset daily code state if a PST day has elapsed
      resetDailyCode(state, now);

      const lastInviteFriendNudge = state.invite_friend_nudge_time ?? 0;
      const elapsedTimeSinceNudge = now - lastInviteFriendNudge;

      // ----------------------------------------------
      // Set cooldown duration for invite drawer

      // how much time has passed since last nudge >= inviteCooldown
      let inviteDrawerDuration = 0;
      if (state.taps > 0) {
        const inviteCooldown = 1000 * 60 * 60 * 12;
        inviteDrawerDuration = elapsedTimeSinceNudge - inviteCooldown;
      }

      // ----------------------------------------------

      // reward autobot (if applicable)
      let botEarnings = 0;
      const lastSession = Math.max(
        state.last_session_time,
        state.last_session_end_time,
      );
      const elapsedTimeSinceLastPlayed = now - lastSession;
      if (state.has_auto_tap) {
        let autobotActiveDuration =
          elapsedTimeSinceLastPlayed - autoBotConfig.cooldown;
        if (autobotActiveDuration > 0) {
          if (autobotActiveDuration > autoBotConfig.maxRewardDuration) {
            autobotActiveDuration = autoBotConfig.maxRewardDuration;
          }

          // autobot has a rate of one tap per second
          const autobotTapCount = Math.round(autobotActiveDuration / 1000);
          const pointsPerTap = getTaps(state);
          const points = pointsPerTap * autobotTapCount;

          incrementScore(state, points);

          api.sendAnalyticsEvents([
            {
              eventType: 'CollectAutoTaps',
              eventProperties: {
                amount: points,
                feature: 'auto_taps',
                $subfeature: 'auto_taps_collect',
                originFeature: 'taps',
              },
            },
          ]);
          // }

          botEarnings += points;
        }
      }

      const powerUpBonus = claimPowerUpReward(state, now);

      handleDailyRewards(state, now, api);

      // claim referral rewards
      let unclaimedReferralRewards = 0;
      if (
        state.unclaimed_referral_rewards &&
        state.unclaimed_referral_rewards > 0
      ) {
        unclaimedReferralRewards = state.unclaimed_referral_rewards;
        incrementScore(state, state.unclaimed_referral_rewards);
        state.unclaimed_referral_rewards = 0;
      }

      state.last_session_time = now;

      // @TODO: hook up tutorial here
      // state.energy = 5;

      return {
        inviteDrawerDuration,
        botEarnings,
        powerUpBonus,
        unclaimedReferralRewards,
      };
    },
  ),
  kickOffNewSeason: action((state, _: void, api) => {
    if (state.season !== SEASON) {
      state.season = SEASON;
    }
  }),
  addBadges: action((state, _: void, api) => {
    const now = api.date.now();
    const newBadges = getBadges(state, now);
    const powerups = getPowerUps(state, now);

    Object.keys(newBadges).forEach((badgeType) => {
      const cat = badgeType as BadgeItem;
      const existingItems = state.badgeControl[cat] ?? [];
      const ownedItems = state.powerUps.owned;
      const whitelisted = state.badgeControl.whitelisted;

      // only show whitelisted or truly new items
      const newItems = newBadges[cat].filter((trigger) => {
        if (whitelisted.includes(trigger.id)) {
          return true;
        }

        return (
          !ownedItems[trigger.id] &&
          !existingItems.some((badge) => badge.id === trigger.id)
        );
      });

      // powerups: now let's filter and remove outdated or those which user already owns
      let newSet = [...existingItems, ...newItems];
      if (cat === 'cards') {
        newSet = newSet.filter((badge) => {
          const powerUp = powerups.find((pu) => pu.id === badge.id);
          // card was assigned in a bucket and user is not in
          if (!powerUp) {
            return false;
          }

          // powerup is not there yet
          if (powerUp.startTime && powerUp.startTime > now) {
            return false;
          }

          // expired or owned
          if (powerUp.specialState && powerUp.specialState !== 'Available') {
            return false;
          }
          // rest are valid
          return true;
        });
      }
      state.badgeControl[cat] = newSet;
    });
    state.badgeControl.whitelisted = [];

    state.badgeControl.lastUpdated = now;
  }),

  onAdminCreateUser: asyncAction(async (state, _payload, api) => {
    // place here additional process if necessary
  }),

  tap: action((state, _, api) => {
    const taps = getConstrainedTaps(1);

    // update user state score
    consumeTaps(state, taps, api);
  }),

  setProfilePicture: action((state, args: { profilePictureUrl: string }) => {
    state.profile.photo = args.profilePictureUrl;
  }),

  setWalletInfo: action(
    (state, payload: { app_name: string; address: string }) => {
      let walletExists = false;
      state.wallet.forEach((wallet) => {
        if (wallet.app_name === payload.app_name) {
          walletExists = true;
          wallet.address = payload.address;
        }
      });

      if (!walletExists) {
        state.wallet.push(payload);
      }
    },
  ),

  buyBooster: action(
    (state, payload: { booster: Booster }, api): void | ExpectedError => {
      const { booster } = payload;

      const blevel = getBoosterLevel(state, booster);
      const currentLevel =
        booster === 'AutoTap' ? Boolean(blevel) : Number(blevel);

      try {
        if (booster === 'AutoTap') {
          if (currentLevel) {
            throw new Error(`User already has this product.`);
          }

          const price = getBoosterPrice(booster, 0);
          acquireBooster(state, api, { booster, level: 1, price });
        } else {
          const nextLevel = (currentLevel as number) + 1;
          const price = getBoosterPrice(booster, currentLevel as number);

          if (!price) {
            throw new Error(`User has reached max level of this item`);
          }

          acquireBooster(state, api, { booster, level: nextLevel, price });
        }
      } catch (e: any) {
        console.log(`Failed to buy booster`, e);
        if (!e?.message) {
          throw e;
        }
        return {
          expectedError: true,
          errorMessage: e.message,
        };
      }
    },
  ),

  buyBuff: action(
    (state, payload: { buff: Buff }, api): void | ExpectedError => {
      const buff = payload.buff;
      const now = api.date.now();
      const buffInfo = getBuffInfo(state, now);

      // @todo: convert to switch-case for exhaustive type checking
      if (buff === 'Rocketman') {
        if (buffInfo.rocketmanUseCount >= FREE_ROCKETMAN_USES) {
          const errorMessage = 'Cannot use more free rocketman at the moment';
          api.sendAnalyticsEvents([
            {
              eventType: 'UseRocketmanError',
              eventProperties: {
                error_message: errorMessage,
                feature: 'rocketman',
                $subfeature: 'rocketman_buy',
                originFeature: 'boosts',
                originSubFeature: 'boosts_rocket',
              },
            },
          ]);
          return {
            expectedError: true,
            errorMessage,
          };
        }

        state.free_rocketman_used.push(now);

        // send analytics events
        api.sendAnalyticsEvents([
          {
            eventType: 'UseRocketman',
            eventProperties: {
              feature: 'rocketman',
              $subfeature: 'rocketman_used',
              originFeature: 'boosts',
              originSubFeature: 'boosts_rocket',
            },
          },
        ]);

        return;
      }

      if (buff === 'FullEnergy') {
        const timeLeft = getTimeLeft(
          buffInfo.lastEnergyUsed,
          FREE_ENERGY_RECHARGE_COOLDOWN,
          now,
        );
        if (timeLeft > 0) {
          const errorMessage = 'Cannot use more free energy at the moment';
          api.sendAnalyticsEvents([
            {
              eventType: 'UseFreeEnergyError',
              eventProperties: {
                error_message: errorMessage,
                feature: 'full_energy',
                $subfeature: 'full_energy',
                originFeature: 'boosts',
                originSubFeature: 'boosts_full_energy',
              },
            },
          ]);
          return {
            expectedError: true,
            errorMessage,
          };
        }

        state.free_energy_recharge_timestamp = now;
        state.energy = getEnergyLimit(state);

        // send analytics events
        api.sendAnalyticsEvents([
          {
            eventType: 'UseFreeEnergy',
            eventProperties: {
              feature: 'full_energy',
              $subfeature: 'full_energy',
              originFeature: 'boosts',
              originSubFeature: 'boosts_full_energy',
            },
          },
        ]);

        return;
      }

      throw new Error(`Buff unsupported: "${buff}"`);
    },
  ),

  claimEarning: action(
    (state, { earningKey }: { earningKey: EarningKey }, api) => {
      return addEarningReward(state, { earningKey });
    },
  ),
  updateQuest: action((state, { questId }: { questId: string }, api) => {
    return updateQuest(state, api.date.now(), { questId });
  }),

  claimYoutubeReward: action((state, _payload: void, api) => {
    // already claimed
    if (state.earnings.followOnYoutube || state.followOnYoutubeTimestamp) {
      return;
    }
    state.followOnYoutubeTimestamp = api.date.now();
  }),

  grantWatchVideoReward: action((state, payload: { id?: string }, api) => {
    addVideoQuestReward(state, payload, api);
  }),

  startWatchVideoWaitingPeriod: action(
    (state, payload: { id?: string }, api) => {
      if (!payload.id) {
        return;
      }
      const stateVideo = state.watchedVideos.find((v) => v.id === payload.id);
      const now = api.date.now();
      // timer already started
      if (stateVideo) {
        return;
      }
      const video = {
        id: payload.id,
        timestamp: now,
        claimed: false,
      };

      state.watchedVideos.push(video);
    },
  ),

  updateInviteFriendNudge: action((state, _: void, api) => {
    state.invite_friend_nudge_time = api.date.now();
  }),

  setQuestAsPromoted: action((state, { questId }: { questId: string }, api) => {
    const quest = state.quests[questId];
    if (quest) {
      quest.promoted = true;
    } else {
      state.quests[questId] = {
        state: 'default',
        promoted: true,
      };
    }
  }),

  handleFirstEntry: asyncAction(
    async (
      state,
      payload: {
        referrer?: string;
        telegramUser: TelegramUser;
        tokenId?: string;
      },
      api,
    ) => {
      if (!state.first_interaction) {
        return;
      }

      state.energy = getBaseEnergy(state);

      await handleFirstEntry(state, payload, api);
    },
  ),

  handleReentry: action((state, _: void, api) => {
    state.first_session = false;
  }),

  handleMineGiftEntry: asyncAction(
    async (state, payload: { card: string; senderId: string }, api) => {
      const { senderId, card } = payload;

      const now = api.date.now();
      const allPowerUps = getPowerUps(state, now);
      let powerUpCard = allPowerUps.find((pu) => pu.id === card);
      let powerUpItem = getActivePowerUpById(card);

      if (!powerUpCard || !powerUpItem) {
        return undefined;
      }

      if (hasReceivedFromUserToday(state, powerUpItem, senderId)) {
        return undefined;
      }

      const senderUsername = await api.asyncGetters.getFriendUsernameById({
        userId: senderId,
      });

      // max discount check not needed for gift only cards
      if (powerUpCard?.type !== PowerUpCardType.GIFT_ONLY) {
        if (hasReachedMaxDiscount(state, card)) {
          return {
            card,
            isNew: false,
            discount: getGiftDiscount(state, card),
            sender: senderUsername,
          };
        }
      }

      // Add the new sender with todays current timestamp
      if (powerUpCard?.type === PowerUpCardType.GIFT_ONLY) {
        state.powerUps.conditions.gift_only[card].dailyGifts.push(senderId);
      } else {
        state.powerUps.conditions.gift_daily_with_discount[
          card
        ].dailyGifts.push(senderId);
      }

      let isNew = false;
      let originalCard = card;
      let actualCard = card;
      if (!state.powerUps.owned[card]) {
        isNew = true;
        try {
          const responseOrError = await buyPowerUp(
            state,
            now,
            {
              id: card,
              isFree: true,
              findAvailGiftIfEnded: true,
            },
            api,
          );
          if (isExpectedError(responseOrError)) {
            return responseOrError;
          }

          const { originalPowerUpId, powerUpId } = responseOrError;
          originalCard = originalPowerUpId;
          actualCard = powerUpId;
        } catch (e) {
          throw e;
        }
      } else if (powerUpCard.type === PowerUpCardType.GIFT_ONLY) {
        try {
          const responseOrError = await buyPowerUp(
            state,
            now,
            {
              id: card,
              isFree: true,
            },
            api,
          );
          if (isExpectedError(responseOrError)) {
            return responseOrError;
          }

          const { originalPowerUpId, powerUpId } = responseOrError;
          originalCard = originalPowerUpId;
          actualCard = powerUpId;
        } catch (e) {
          throw e;
        }
      } else if (
        powerUpCard.type !== PowerUpCardType.GIFT_ONLY &&
        getCanReceiveDiscount(state, card, senderId)
      ) {
        state.powerUps.conditions.gift_daily_with_discount[
          card
        ].discountList.push(senderId);
      }

      if (actualCard !== originalCard) {
        powerUpCard = allPowerUps.find((pu) => pu.id === actualCard);
        powerUpItem = getActivePowerUpById(actualCard);

        // recheck for typescript but probably actually always exists
        if (!powerUpCard || !powerUpItem) {
          return undefined;
        }
      }

      return {
        card: actualCard,
        name: powerUpItem?.name ?? 'Unknown',
        isNew,
        discount:
          powerUpCard.type === PowerUpCardType.GIFT_ONLY
            ? undefined
            : getGiftDiscount(state, card),
        sender: senderUsername,
        cardType: powerUpCard.type,
        level: powerUpCard.level,
        originalCard,
      };
    },
  ),
  handleMinePromoEntry: asyncAction(
    async (state, payload: { card: string }, api) => {
      const { card } = payload;

      const now = api.date.now();
      const allPowerUps = getPowerUps(state, now);
      let powerUpCard = allPowerUps.find(
        (item) =>
          item.id === card &&
          item.specialState === 'Available' &&
          item.type === PowerUpCardType.HIDDEN,
      );

      let powerUpItem = getActivePowerUpById(card);

      const isPromoAvailable = powerUpCard !== undefined;
      if (isPromoAvailable == false) {
        return undefined;
      }

      let actualCard = card;
      if (!state.powerUps.owned[card]) {
        try {
          const responseOrError = await buyPowerUp(
            state,
            now,
            {
              id: card,
              isFree: true,
            },
            api,
          );
          if (isExpectedError(responseOrError)) {
            return responseOrError;
          }

          const { originalPowerUpId, powerUpId } = responseOrError;
          actualCard = powerUpId;
        } catch (e) {
          throw e;
        }
      }

      return {
        card: actualCard,
      };
    },
  ),
  buyPowerUp: asyncAction(async (state, payload: { id: string }, api) => {
    const result = await buyPowerUp(state, api.date.now(), payload, api);
    return result;
  }),
  updateBucket: action(
    (
      state,
      payload: { bucket: ABTestID; value: ABTestBucket<ABTestID> },
      api,
    ) => {
      if (state.ruleset.abTests[payload.bucket]) {
        api.abTests.assign(payload.bucket, payload.value);
      }
    },
  ),
  claimDailyReward: action((state, _: void, api) => {
    if (!state.streak_days || !state.unclaimed_rewards) {
      return false;
    }
    const unclaimedRewards = getCombinedUnclaimedRewards(state);
    incrementScore(state, unclaimedRewards);

    state.unclaimed_rewards = 0;

    api.sendAnalyticsEvents([
      {
        eventType: 'retention_reward_claim',
        eventProperties: {
          // use +1 here becase the consecutive_days is 0-indexed
          return_day: state.consecutive_days + 1,
          feature: 'rewards',
          $subfeature: 'rewards_daily_claim',
          originFeature: 'drawer',
          originSubFeature: 'drawer_daily_reward',
        },
      },
    ]);

    return true;
  }),
  claimDailyContractCheckin: action((state, _: void, api) => {
    if (state.dailyContractCheckin === undefined) {
      return;
    }

    if (api.date.now() - state.dailyContractCheckin < ONE_DAY_MS) {
      // already checked in today
      return;
    }

    incrementScore(state, DAILY_CHECKIN_CONTRACT_REWARD);
    state.dailyContractCheckin = api.date.now();

    api.sendAnalyticsEvents([
      {
        eventType: 'daily_contract_checkin_reward_claim',
        eventProperties: {
          feature: 'rewards',
          $subfeature: 'rewards_daily_contract_checkin_claim',
          originFeature: 'drawer',
          originSubFeature: 'drawer_daily_contract_reward',
        },
      },
    ]);
  }),
  initPowerUpSpecials: action((state, _: void, _api) => {
    initForRequireMoreFriendsFromLast(state);
  }),

  cleanBadge: action(
    (state, payload: { ids: string[]; type: BadgeItem }, api) => {
      const badges = state.badgeControl[payload.type];
      const newArray = badges.filter(
        (badge) => !payload.ids.includes(badge.id),
      );
      state.badgeControl[payload.type] = newArray;
    },
  ),

  checkMembership: asyncAction(async (state, _: void, api) => {
    try {
      const membership = await getChatMember({
        chatId: '@gemz_announcements',
        userId: state.id,
        fetch: api.fetch,
      });

      return membership;
    } catch (e) {
      return e;
    }
  }),
  updateStateFromTutorial: action(
    (
      state,
      payload: {
        tutorialId: string;
        stepId?: string;
        stepIndex: number;
        test: boolean;
      },
      api,
    ) => {
      return handleTutorialUpdate(state, payload, api.date.now());
    },
  ),

  resetEarningWalletConnect: action((state, _, _api) => {
    if (!state.earnings.walletConnect) {
      // already false, so not applicable
      return;
    }

    if (state.wallet.length > 0) {
      // has wallet, so state is correct
      return;
    }

    state.earnings.walletConnect = false;
  }),
  onDailyCodeComplete: action((state, { code }: { code: string }, api) => {
    const dailyCode = getDailyCode(api.date.now());
    if (!dailyCode) {
      return false;
    }
    const isCorrect = dailyCode.toLowerCase() === code.toLowerCase();
    if (isCorrect) {
      incrementScore(state, DAILY_CODE_PRIZE);
      state.dailyCode.complete = true;
      return true;
    } else {
      return false;
    }
  }),
  reloadDailyReward: action((state, _, api) => {
    handleDailyRewards(state, api.date.now(), api);
  }),
  checkForQuestReady: action((state, _, api) => {
    const readyToClaimIds = getQuestsReadyToClaim(state, api.date.now());
    readyToClaimIds.forEach((id) => {
      if (state.quests[id]) {
        state.quests[id].state = 'ready_to_claim';
      }
    });
  }),
  setMaxSlippage: action((state, { value }: { value: number }, _) => {
    state.trading.maxSlippage = value;
  }),
  removeLabels: action((state, { labels }: { labels: string[] }, _) => {
    state.labels = state.labels.filter((l) => !labels.includes(l));
  }),
  addLabels: action((state, { labels }: { labels: string[] }, _) => {
    labels.forEach((label) => {
      if (!state.labels.includes(label)) {
        state.labels.push(label);
      }
    });
  }),
  asyncSyncTgStarsTxAndCredits: asyncAction(async (state, _: void, api) => {
    const txs = await api.purchases.getPurchaseHistory();
    const nonConsumedTxs = txs.filter((tx) => !tx.is_consumed);
    let anySync = false;
    for (const tx of nonConsumedTxs) {
      try {
        await api.purchases.consumePurchase({ productId: tx.product_id });
        state.tokenCreationCredits++;
        anySync = true;
      } catch {
        // What can we even do?
        return false;
      }
    }
    return anySync;
  }),
});
