import { ModalLabels } from '../../../replicant/ruleset';

/**
 * @use Add to the list the label of a modal you want to show once
 * @warning
 * Always add items to the end of the list;
 * Never change the order;
 */
export const initModalList = [
  ModalLabels.SHOW_TIKTOK_TEASER_MODAL_2,
  ModalLabels.SHOW_TIKTOK_TEASER_S3,
];
/* !!!SEE WARNING BEGINNING OF THE FILE!!! */
