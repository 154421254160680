import './DrawerTeaserS3.scss';
import { useTranslation, Trans } from 'react-i18next';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/Controllers/AppController';
import { isMobileEmulatedByBrowser } from '../../../data/device';

function getResponsiveClassName() {
  const isEmu = isMobileEmulatedByBrowser();
  const SE = isEmu ? 600 : 667;
  const Mini = isEmu ? 680 : 780;

  if (window.innerHeight <= SE) return 'SE'; // SE
  if (window.innerHeight <= Mini) return 'Mini'; // Mini
  return 'XR'; // XR
}

// ===================================================================================
// #region TeaserS3

export const DrawerTeaserS3 = () => {
  const { t } = useTranslation();

  const onClose = () => {
    app.ui.drawer.close();
  };

  const rclass = getResponsiveClassName();

  return (
    <div className="drawer-teaser-s3">
      <div className={`drawer-teaser-s3-img-container ${rclass}`}>
        <img src={assets.ftue_teaser_s3} />
      </div>

      <div className="drawer-teaser-s3-content">
        <div className={`header ${rclass}`}>
          <div className="season3">{t('S3_teaser_title')}</div>
          <div className="comingsoon">{t('S3_teaser_subtitle')}</div>
        </div>
        <div className="section s1">
          <div className="rewards">
            <div className="label">
              <Trans
                i18nKey="S3_teaset_new_rewards"
                components={{
                  bold: <strong />,
                }}
              />
            </div>
          </div>
          <div className="rewards">
            <div className="label">
              <Trans
                i18nKey="S3_teaser_new_ways_to_earn"
                components={{
                  bold: <strong />,
                }}
              />
            </div>
          </div>
        </div>

        <div className="section s2">
          <div className="onemorething"></div>
        </div>

        <div className="section s3">
          <Trans
            i18nKey="S3_teaser_get_ready"
            components={{
              bold: <strong />,
            }}
          />
        </div>

        <div className="section s4">
          <div className="btn btn-normal button-teaser" onClick={onClose}>
            {t('S3_teaser_gotit_button')}
          </div>
        </div>
      </div>
    </div>
  );
};

// ===================================================================================
