import './Page.scss';
import { PropsWithChildren, useEffect, useState } from 'react';
import { ButtonBack } from '../shared/ButtonBack/ButtonBack';
import { app } from '../../data/Controllers/AppController';

interface Props extends PropsWithChildren {
  id: string;
  visible: boolean;
  showInstantly?: boolean;
  preventClosing?: boolean;
}

export const Page = ({
  children,
  id,
  visible,
  showInstantly,
  preventClosing,
}: Props) => {
  const [hasShownOnce, setHasShownOnce] = useState(false);

  if (!hasShownOnce && visible) {
    setHasShownOnce(true);
  }

  let anim = visible ? 'slide-up' : 'slide-down';
  if (showInstantly) anim = '';

  // debug safe areas
  const debugSafeAreas = false;
  if (visible && debugSafeAreas) {
    var style = getComputedStyle(document.body);
    console.warn(id, '>>> --safe-top', style.getPropertyValue('--safe-top'));
    console.warn(
      id,
      '>>> --safe-height',
      style.getPropertyValue('--safe-height'),
    );
  }

  return (
    <div
      id={id}
      className={`page-container ${id} ${!hasShownOnce && 'hide'} ${anim}`}
    >
      <div className={`page ${id}`}>
        <ButtonBack />

        {children}
      </div>
    </div>
  );
};
