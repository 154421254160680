import { analytics, AnalyticsProperties } from '@play-co/gcinstant';
import { PayloadProperties } from './analytics/properties';
import {
  app,
  gcinstant,
  payloadEncoder,
  Route,
} from './Controllers/AppController';
import { getReferralUrl } from '../replicant/features/chatbot/chatbot.private';
import { getSourceRealtimeStats } from '../replicant/features/game/game.getters';
import { tests } from '../replicant/ruleset';
import config from '../replicant/features/game/game.config';
import { getDayMidnightInUTC } from '../replicant/utils/time';
import { isIOS } from './device';
import { t } from 'i18next';
import { formatPrice } from '../replicant/utils/numbers';
import {
  REFERRAL_BONUS_PREMIUM,
  REFERRAL_BONUS_REGULAR,
} from '../replicant/features/game/ruleset/referrals';
import { getBotName } from './config';

function getInviteMessage() {
  return t('friendstab_invite_text', {
    regularReward: formatPrice(REFERRAL_BONUS_REGULAR),
    premiumReward: formatPrice(REFERRAL_BONUS_PREMIUM),
  });
}

function encodePayload(payload: Record<string, any>) {
  const { $key: encodedPayloadKey } = payloadEncoder.encode(payload);

  if (!encodedPayloadKey) {
    throw Error('Missing $key property in encoded payload');
  }

  return encodedPayloadKey;
}

function telegramShare(
  encodedPayloadKey: string,
  url?: string,
  inviteText?: string,
  inviteTitle?: string,
) {
  // TG share api
  if (inviteTitle) {
    // if there is a title, bake it into the text
    inviteText = `${inviteTitle}\n\n${inviteText}`;
  }

  const shareUrl = url
    ? app.getShareUrl(url, inviteText)
    : app.getReferralShareUrl(encodedPayloadKey, inviteText);

  // We can't tell if the invite was a success or not
  // GCInstant natively uses `postEvent` instead of `openTelegramLink`
  Telegram.WebApp.openTelegramLink(shareUrl);

  // a telegram share is always considered a success
  return true;
}

async function share(
  encodedPayloadKey: string,
  url?: string,
  inviteText?: string,
  inviteTitle?: string,
) {
  // Note:
  // Android has navigator.share api natively, however, android WEBVIEW does not.
  // Therefore, android TG app will alwyas default to telegram api for sharing and inviting.

  const isSharingToTelegram = true;

  if (isSharingToTelegram) {
    return telegramShare(encodedPayloadKey, url, inviteText, inviteTitle);
  }

  // use native navigator.share feature
  const referralUrl = url || getReferralUrl(encodedPayloadKey, getBotName());
  try {
    await navigator.share({
      url: referralUrl,
      title: inviteTitle || t('friendstab_invite_title'),
      text: inviteText,
    });

    // here we can identify that a share was performed
    return true;
  } catch (error) {
    if ((error as DOMException).name === 'AbortError') {
      // here we can identify that no share was performed due to user cancellation
      telegramShare(encodedPayloadKey, url, inviteText, inviteTitle);
      return;
    }

    console.log({ referralUrl });
    console.error(
      '>>> invite - cannot use navigator.share',
      (error as DOMException).message,
    );

    return telegramShare(encodedPayloadKey, url, inviteText, inviteTitle);
  }
}

function makePayload(payload: {
  [key: string]: unknown;
  $channel: AnalyticsProperties.Channel;
  feature: string;
  $subFeature?: string;
  payload?: Record<string, any>;
}): PayloadProperties {
  const sourceRealtimeData = getSourceRealtimeStats(app.state, app.now());
  return {
    $realtimeIsPurchaser: false,
    $realtimeLTV: 0,
    ...payload,
    payload: {
      ...sourceRealtimeData,
      ...payload?.payload,
    },
    ...gcinstant.getPlatformPayloadData(),
  };
}

// TODO move into GCInstant
export async function invite(args: {
  screen_location: string;
  url?: string;
  data: {
    feature: string;
    $subFeature: string;
    originFeature?: string;
    originSubFeature?: string;
  };
  trackPressInvite?: boolean;
}) {
  const payload = makePayload({
    ...args.data,
    $channel: 'INVITE',
  });

  analytics.pushEvent('TrackInviteAsync', payload);

  if (args.trackPressInvite) {
    app.track('PressInvite', {
      screen_location: args.screen_location,
      ...args.data,
    });
  }

  const { $key: encodedPayloadKey } = payloadEncoder.encode(payload);

  if (!encodedPayloadKey) {
    throw Error('Missing $key property in encoded payload');
  }

  const inviteText = args.url ? '' : getInviteMessage();

  await share(encodedPayloadKey, args.url, inviteText);
}

export function generateInviteUrlWithPayload(args: {
  screen_location: string;
  data: {
    feature: string;
    $subFeature: string;
  };
}) {
  const payload = makePayload({
    screen_location: args.screen_location,
    ...args.data,
    $channel: 'LINK',
  });

  analytics.pushEvent('TrackInviteCopyAsync', payload);

  const encodedPayloadKey = encodePayload(payload);

  const url = getReferralUrl(encodedPayloadKey, getBotName());

  // return `${url}\n\n${getInviteMessage()}`;

  return url;
}

export async function sendUserMemeGift({
  memeId,
  memeName,
  shareTime,
  feature,
}: {
  memeId: string;
  memeName: string;
  shareTime: number;
  feature: string;
  subFeature?: string;
}) {
  const payload = makePayload({
    feature,
    $subFeature: 'user_meme_gift',
    $channel: 'INVITE',
  });

  analytics.pushEvent('UserMemeGiftShare', {
    ...payload,
    memeId,
  });

  const encodedPayloadKey = encodePayload({
    ...payload,
    payload: {
      memeId,
      shareTime,
      senderName: app.state.username,
    },
  });

  await share(
    encodedPayloadKey,
    undefined,
    t('user_meme_gift_msg_text', { memeName }),
  );
}

export async function sendMineCardGift({
  card,
  originFeature,
  originSubFeature,
}: {
  card: string;
  originFeature?: string;
  originSubFeature?: string;
}) {
  const payload = makePayload({
    feature: 'mine',
    $subFeature: 'gift',
    $channel: 'INVITE',
    card,
  });

  analytics.pushEvent('TrackMineGiftAsync', {
    ...payload,
    card,
    gift_name: card,
    originFeature,
    originSubFeature,
  });

  const encodedPayloadKey = encodePayload({
    ...payload,
    payload: {
      ...(payload as any).payload,
      card,
      createdAt: getDayMidnightInUTC(app.now()),
    },
  });

  const shareUrl = app.clicker.getMineGiftUrl(encodedPayloadKey, card);

  // At this point the web app closes and we can't tell if the invite was a success or not
  // GCInstant natively uses `postEvent` instead of `openTelegramLink`
  Telegram.WebApp.openTelegramLink(shareUrl);
}

export function generatePromoLink(card: string, origin: string = 'default') {
  const payload = makePayload({
    feature: 'mine',
    $subFeature: 'promo',
    $channel: 'LINK',
    card: card,
    origin: origin,
  });

  const encodedPayloadKey = encodePayload({
    ...payload,
    payload: { card },
  });

  return `https://t.me/${getBotName()}/tap?startapp=${encodedPayloadKey}`;
}

// Returns share url
export async function shareDeeplink(
  route: Route,
  {
    messageOpts,
    deeplinkOpts,
    skipShare,
  }: {
    messageOpts?: { title: string; text: string };
    deeplinkOpts?: Record<string, any>;
    skipShare?: boolean;
  },
) {
  const payload = makePayload({
    feature: 'deeplink',
    $subFeature: route,
    $channel: 'LINK',
  });

  const customPayload = {
    dlRoute: route,
    dlOpts: deeplinkOpts,
  };

  const encodedPayloadKey = encodePayload({
    ...payload,
    payload: {
      ...(payload as any).payload,
      ...customPayload,
    },
  });

  if (skipShare) {
    return getReferralUrl(encodedPayloadKey, getBotName());
  }

  await share(
    encodedPayloadKey,
    undefined,
    messageOpts?.text,
    messageOpts?.title,
  );
}
