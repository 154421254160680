import './TradingTokenItemProfile.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { getUIStateClassName } from '../../../../data/utils';
import { app } from '../../../../data/Controllers/AppController';
import {
  formatPrice,
  largeIntegerToLetter,
} from '../../../../replicant/utils/numbers';
import { TradingSearchResult } from '../../../../replicant/features/offchainTrading/offchainTrading.properties';

interface Props {
  offchainTokenData: TradingSearchResult;
}

export const TradingTokenItemProfile = ({ offchainTokenData }: Props) => {
  const { t } = useTranslation();

  const { id: offchainTokenId, profile } = offchainTokenData;

  const tickerName = profile.ticker;

  const onShowOffchainTokenDetails = async () => {
    // navigate to tiktok feed from profile, with category set to either Owned or Created
    // get which tab category are we going to display in tiktok page
    const { ownedOrCreatedOrFarming } = app.ui.state.profile;
    const ownedOrCreated =
      ownedOrCreatedOrFarming === 'Farming' ? 'Owned' : ownedOrCreatedOrFarming;
    app.nav.goToTiktokFeed(offchainTokenId, ownedOrCreated, 'profile-list');
  };

  if (!app.views.ProfilePage.visible) {
    return null;
  }

  // retrieving data from profile controller
  const myToken = app.profile.getTokenHolding(offchainTokenId);

  const holdingStats = app.profile.getOffchainTokenHoldingStats(
    offchainTokenId,
    myToken,
  );

  const valuation = holdingStats?.valuation || 0;
  const roi = holdingStats?.roi || 0;
  const marketCap = holdingStats?.marketCap || 0;

  const color = roi === 0 ? 'white' : roi > 0 ? 'green' : 'red';
  const iconArrow = roi > 0 ? assets.arrow_up_green : assets.arrow_down_red;

  // console.warn('>>> item', name, offchainTokenId, holdingStats);

  return (
    <div
      className={`btn trading-token-item-profile ${getUIStateClassName(
        app.ui.getCardState(offchainTokenId),
      )}`}
      onClick={onShowOffchainTokenDetails}
    >
      <div className="trading-token-item-profile-container">
        <div className="trading-token-item-profile-image">
          <img src={profile.image} />
        </div>

        <div className="trading-token-item-profile-content">
          <div className="left">
            <div className="name-container">
              <div className="tickername">{tickerName}</div>
              <div className="tokenname">{profile.name}</div>
            </div>

            {/* market cap */}
            <div className="trading-token-item-market-cap">
              <div className="trading-token-item-market-cap-label">
                {t('trading_token_item_market_cap')}
              </div>
              <div className="trading-token-item-market-cap-value">
                <div className="icon">
                  <img src={assets.coin} />
                </div>
                <div className="label">
                  {largeIntegerToLetter(marketCap, 3)}
                </div>
              </div>
              {/* todo(Cai): hook this with real data */}
              {/* <div className="trading-token-item-market-cap-percent">+300%</div> */}
            </div>
          </div>

          <div className="right">
            <div className="price-container">
              <div className="coin">
                <img src={assets.coin} />
              </div>
              {/* valuation */}
              <div className="value">{largeIntegerToLetter(valuation, 3)}</div>
            </div>
            <div className="percent-container">
              <div className={`arrow ${color}`}>
                {roi !== 0 && <img src={iconArrow} />}
              </div>
              {/* change */}
              <div className={`percent ${color}`}>
                {formatPrice(Math.round(roi))}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
