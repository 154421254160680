import { t } from 'i18next';
import { assets } from '../../../../assets/assets';
import { app, useAppUpdates } from '../../../../data/Controllers/AppController';
import {
  getTransactionLabel,
  msToAgo,
  upperFirst,
} from '../../../../data/utils';
import { getCurvePrice } from '../../../../replicant/features/offchainTrading/offchainTrading.getters';
import { TradingTx } from '../../../../replicant/features/offchainTrading/offchainTrading.schema';
import { TradingTokenHolder } from '../../../../replicant/features/offchainTrading/types';
import {
  displayPrice,
  displayTokenAmount,
  formatPrice,
  largeIntegerToLetter,
} from '../../../../replicant/utils/numbers';
import { TradingSocialBtn } from './TradingSocialBtn';
import { TradingChart } from '../Charts/TradingChart';
import Big from 'big.js';
import { resolveProfilePicture } from '../../../../replicant/features/game/game.getters';
import { UIEvents } from '../../../../data/Controllers/UIController/UITypes';
import { MemesEvents } from '../../../../data/Controllers/Memes/MemesController';

export const TradingOverview = () => {
  if (!app.memes.currentMeme.token) {
    return null;
  }

  const { shortDescription, marketCap, numOfHolders } =
    app.memes.currentMeme.token.overview;

  let marketCapValue =
    marketCap instanceof Big ? marketCap.toNumber() : marketCap;

  return (
    <div className="trading-section overview">
      <div className="text">{shortDescription}</div>
      <div className="item market-cap">
        <div className="label">{t('trading_token_item_market_cap')}</div>
        <div className="value">
          <div className="value">{displayPrice(marketCapValue, 4)}</div>
          <div className="icon">
            <img src={assets.coin} />
          </div>
        </div>
      </div>
      <div className="item holders">
        <div className="label">{t('trading_token_item_num_holders')}</div>
        <div className="value">
          <div className="value">{formatPrice(numOfHolders)}</div>
          <div className="icon">
            <img src={assets.icon_holders} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const TradingAbout = () => {
  if (!app.memes.currentMeme.token) {
    return null;
  }

  const { description, telegramChannelLink, telegramChatLink, twitterLink } =
    app.memes.currentMeme.token.description;

  return (
    <div className="trading-section about">
      <div className="text two-line-ellipsis">{description}</div>

      <div className="about-items">
        <TradingSocialBtn
          className="channel"
          icon={assets.paperplane}
          title={t('trading_token_tabs_content_channel')}
          url={telegramChannelLink}
        />
        <TradingSocialBtn
          className="group"
          icon={assets.paperplane}
          title={t('trading_token_tabs_content_chat')}
          url={telegramChatLink}
        />
        <TradingSocialBtn
          className="channel"
          icon={assets.paperplane}
          title={t('trading_token_tabs_content_twitter')}
          url={twitterLink}
        />
      </div>
    </div>
  );
};

// -------------------------

interface PropsHolder {
  data: TradingTokenHolder;
}

export const TradingTokenHoldersItem = ({ data }: PropsHolder) => {
  const tickerName = app.memes.currentMeme.token?.ticker ?? '#';

  const goToProfile = () => {
    app.nav.goToProfile(data.userId);
  };

  const tokenAmount = displayTokenAmount(
    data.offchainTokenCount.toNumber(),
    4,
    false,
  );

  return (
    <div className="trading-token-holder-item" onClick={goToProfile}>
      <div className="holder-left">
        <div className="image">
          <img src={data.image} />
        </div>

        <div className="info">
          <div className="top">
            {data.type === 'creator' && (
              <div className="creator">{`[${data.type}]`}</div>
            )}
            <div className="name">{data.name}</div>
          </div>
          <div className="offchainTokens">{`${tokenAmount} ${tickerName}`}</div>

          {/* <div className="bottom">
            <div className={`type ${data.type}`}>{data.type}</div>
            <div className="icon">
              <img src={assets.coin} />
            </div>
            <div className="value">{largeIntegerToLetter(data.value)}</div>
          </div> */}
        </div>
      </div>

      <div className="holder-right">
        <div className="offchainTokens">~ {data.offchainTokenMarketShare}</div>
        {/* <div className="time">{data.time}</div> */}
      </div>
    </div>
  );
};

// -------------------------

interface PropsTransaction {
  tx: TradingTx;
  onClick?: (userId: string) => void;
}

export const TradingTokenTransactionItem = ({
  tx,
  onClick,
}: PropsTransaction) => {
  if (!app.memes.currentMeme.token) {
    return null;
  }

  const tokenAmount = tx.tokenAmount;
  const txPrice = tx.currencyAmount;
  const txCurrency = tx.currency;
  const timeAgo = app.now() - tx.createdAt;

  const tickerName = app.memes.currentMeme.token.ticker;

  // in old format the transaction did not have a user name and photo
  // only use the transaction user name and photo if using new transaction format
  // otherwise use the creator name/photo
  const isNewTxFormat = !!tx.userName;
  const userName = isNewTxFormat
    ? tx.userName
    : app.memes.currentMeme.token.creatorName;
  const userImage = isNewTxFormat
    ? resolveProfilePicture(tx.userId, tx.userImage)
    : app.memes.currentMeme.token.creatorImage;

  const goToProfile = () => {
    app.nav.goToProfile(tx.userId);
    onClick && onClick(tx.userId);
  };

  const tokenAmountStr = displayTokenAmount(
    Big(tokenAmount).toNumber(),
    4,
    true,
  );

  const txLabel = getTransactionLabel(tx);

  return (
    <div className="trading-token-transaction-item" onClick={goToProfile}>
      <div className="transaction-left">
        <div className="image">
          <img src={userImage} />
        </div>

        <div className="info">
          <div className="name">{userName}</div>
          <div className="bottom">
            <div className={`type ${tx.txType.toLowerCase()}`}>{txLabel}</div>
            {txCurrency !== 'points' && txCurrency !== 'gift' && (
              <>
                <div className="icon">
                  <img src={assets.coin} />
                </div>
                <div className="label coins">
                  {largeIntegerToLetter(Big(txPrice).toNumber())}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="transaction-right">
        <div
          className="offchainTokens"
          dangerouslySetInnerHTML={{ __html: tokenAmountStr }}
        ></div>
        <div className="offchainTokens">${tickerName}</div>
        <div className="time">{msToAgo(timeAgo)}</div>
      </div>
    </div>
  );
};

// -------------------------

interface TradingPriceAreaProps {
  showChart?: boolean;
}

export const TradingPriceArea = ({ showChart }: TradingPriceAreaProps) => {
  useAppUpdates({
    id: 'TradingPriceArea',
    listener: app.memes.attachEventListener(MemesEvents.TradingOnTxUpdate),
  });

  useAppUpdates({
    id: 'OnFooterExpanded',
    listener: app.ui.attachEventListener(UIEvents.OnFooterExpanded),
  });

  const curvePrice = getCurvePrice(
    Big(app.memes.currentMeme.token?.supply ?? 0),
  );
  return (
    <div
      className="trading-price-area-container"
      style={{ paddingBottom: app.ui.footerExpanded ? '270px' : '35px' }}
    >
      <div className="trading-price-area">
        <div className="price-area-left">
          <div className="current">
            {/* todo(Cai): hook up timer */}
            <div className="label">{t('trading_token_current_price')}</div>
            <div className="price">
              <div className="icon">
                <img src={assets.coin} />
              </div>
              <div className="value">
                {curvePrice ? displayPrice(curvePrice.toNumber(), 4) : '-'}
              </div>
              {/* <div className="percent green">{`${formatPrice(
                app.memes.currentMeme.token?.changePerHour || 0,
              )}%`}</div> */}
            </div>
          </div>

          {/* <div className="change">
            <div className="label">{'1h Chg%'}</div>
            <div className="value green">{`${formatPrice(
              data?.changePerHour || 0,
            )}%`}</div>
          </div> */}
        </div>

        <div className="price-area-right">
          <div
            className="btn image"
            onClick={() => {
              app.ui.drawer.show({
                id: 'drawerTradingComingSoon',
                hideClose: true,
              });
            }}
          >
            <img src={assets.trading_price_area_icon} />
          </div>
        </div>
      </div>

      {/* trading chart */}
      {showChart && app.memes.currentMeme.token && (
        <TradingChart tokenState={app.memes.currentMeme.token} height={250} />
      )}
    </div>
  );
};
