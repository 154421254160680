import {
  createAdminMessage,
  createMessage,
  createMessages,
  SB,
} from '@play-co/replicant';
import schema from '../../schema';
import { MAX_FRIEND_COUNT } from './ruleset/friends';
import {
  incrementScore,
  incrementTokenCreationCredits,
} from './game.modifiers';
import {
  tmgInitState,
  updateTokenHolding,
} from '../offchainTrading/offchainTrading.modifiers';
import Big from 'big.js';
import { DAY_IN_MS } from '../../utils/time';
import { getOwnMemeGiftId } from '../powerups/getters';

export const gameMessages = createMessages(schema)({
  addReferrerScore: createMessage(
    SB.object({ bonus: SB.int() }),
    (state, { bonus }, info) => {
      incrementScore(state, bonus);
    },
  ),
  addUnclaimedReferrerScore: createMessage(
    SB.object({ rewards: SB.int() }),
    (state, { rewards }, info) => {
      state.unclaimed_referral_rewards += rewards;
    },
  ),
  claimRewards: createMessage(
    SB.object({ rewards: SB.int() }),
    (state, { rewards }, info) => {
      incrementScore(state, rewards);
    },
  ),
  follow: createMessage(SB.object({}), (state, {}, info) => {
    state.followersCount++;
  }),
  unfollow: createMessage(SB.object({}), (state, {}, info) => {
    state.followersCount--;
  }),
  // Deprecates
  // @deprecated
  addFriendContribution: createMessage(SB.unknown(), (state, _, info) => {}),
  // @deprecated
  transferFriends: createMessage(SB.unknown(), (state, _, info) => {}),
  // @deprecated
  addFriend: createMessage(
    SB.object({
      friendId: SB.string(),
      bonus: SB.int(),
    }),
    (state, { friendId, bonus }, info) => {
      if (!state.friendIds) {
        // this message should not be called if friends are not defined
        console.error('addFriend called with undefined state.friendIds');
        return;
      }

      // if friend already exists, we dont want to add bonuses
      if (state.friendIds.includes(friendId)) {
        return;
      }

      // the player cannot have more than a given amount of friends
      // but can still receive bonuses from referrals
      if (state.friendIds.length < MAX_FRIEND_COUNT) {
        state.friendIds.push(friendId);
      }

      incrementScore(state, bonus);
    },
  ),
  // Admin messages
  addRewards: createAdminMessage(
    SB.object({ rewards: SB.int() }),
    (state, { rewards }, info) => {
      incrementScore(state, rewards);
    },
  ),
  addTokenCredits: createAdminMessage(
    SB.object({ credits: SB.int() }),
    (state, { credits }, info) => {
      incrementTokenCreationCredits(state, credits);
    },
  ),
  addGameTickets: createAdminMessage(
    SB.object({ tokenId: SB.string().optional(), tickets: SB.int() }),
    (state, { tokenId, tickets }, info) => {
      if (tokenId) {
        state.trading.miniGames.state[tokenId].tickets += tickets;
      } else {
        state.trading.miniGames.tapping.tickets += tickets;
      }
    },
  ),
  creditReferrerKickBack: createMessage(
    SB.object({
      points: SB.number(),
      tokenId: SB.string(),
      tokenUpdate: SB.object({
        tokenAmount: SB.string(),
        currencyInvested: SB.string(),
        pointsAccumulated: SB.string(),
        lastNotifPrice: SB.string(),
      }),
    }),
    (state, props, info) => {
      const { tokenId, points, tokenUpdate } = props;

      tmgInitState(state, tokenId);

      // Increase kickback counter;
      const allTimeReferralKickBack =
        state.trading.miniGames.state[tokenId].allTimeReferralKickBack;
      state.trading.miniGames.state[tokenId].allTimeReferralKickBack = Big(
        allTimeReferralKickBack,
      )
        .add(points)
        .toString();

      updateTokenHolding(state, tokenId, tokenUpdate);
    },
  ),
  setGiftAsClaimed: createMessage(
    SB.object({
      tokenId: SB.string(),
      shareTime: SB.int(),
    }),
    (state, props, info) => {
      const { tokenId, shareTime } = props;

      const giftId = getOwnMemeGiftId(tokenId, shareTime);
      const sentGift = state.trading.userMemeGiftsSent[giftId];
      if (!sentGift) {
        return;
      }

      if (sentGift.claimed) {
        return;
      }

      if (sentGift.shareTime + DAY_IN_MS < info.timestamp) {
        return;
      }

      sentGift.claimed = true;
    },
  ),
});
