import { State } from '../../schema';
import { DAY_IN_MS, HOUR_IN_MS } from '../../utils/time';
import {
  ConditionAirtableItem,
  ConditionCfg,
  PUDailyLevelUserCondition,
  PowerUp,
  PowerUpUI,
  PowerUpAirtableItem,
  PowerUpCategory,
  PowerUpCfg,
  PowerUpDailyAirtableItem,
  SpecialPowerUpState,
  PowerUpCardType,
  PowerUpBondingCurves,
} from './types';
import airtablePowerUps from './airtable/powerUpItems';
import airtableConditions from './airtable/powerUpConditions';
import airtableDailies from './airtable/powerUpDaily';
import airtableBondingCurves from './airtable/powerUpBondingCurve';
import { getDayMidnightInUTC } from '../../utils/time';
import {
  PU_DAILY_BONUS_REWARD,
  DISCOUNT_PCT_PER_FRIEND,
  MAX_GIFT_FRIENDS,
  PU_SPECIALS_DAO_ITEM_ID,
  PU_BONUS_MAX_MINING_DURATION,
  GO_DECREASE_FACTOR,
  GO_INITIAL_VALUE,
  GO_LEVEL1_INCREMENTAL_EARNINGS,
  GO_LEVEL2_JUMP_EARNINGS,
  GO_MINIMUM_EARNINGS,
  DF_DECREASE_FACTOR,
  DF_LEVEL1_INCREMENTAL_EARNINGS,
  DF_LEVEL2_JUMP_EARNINGS,
  DF_MINIMUM_EARNINGS,
} from './ruleset';
import { tests } from '../../ruleset';
import { specialCardWaitTimes } from '../game/ruleset/mining';
import {
  PowerUpBondingCurve,
  PUGiftOnlyCostCurve,
  PUPolynomialCostCurve,
  PUPolynomialEarnCurve,
} from '../offchainTrading/types';

type Foo = Record<
  string,
  {
    earn: PowerUpBondingCurve['variables'];
    cost: PowerUpBondingCurve['variables'];
  }
>;
// Record<powerUpId, earn, cost>
/**
 * This function was written to work with the 3 current bonding curves;
 * 2 cost: 'polynomialCost' and 'giftOnlyCost'
 * 1 earn: 'polynomialEarn'
 * assume if not 'polynomialEarn' then it's cost
 */
const bondingCurves = airtableBondingCurves.reduce((res, cur) => {
  if (!res[cur.powerUpId]) {
    // if current is earn just use the value, but if it's not then find the right value
    const earn =
      cur.curveId === 'polynomialEarn'
        ? cur.variables
        : airtableBondingCurves.find(
            (c) =>
              c.powerUpId === cur.powerUpId && c.curveId === 'polynomialEarn',
          )?.variables;
    const cost =
      cur.curveId !== 'polynomialEarn'
        ? cur.variables
        : airtableBondingCurves.find(
            (c) =>
              c.powerUpId === cur.powerUpId &&
              (c.curveId === 'giftOnlyCost' || c.curveId === 'polynomialCost'),
          )?.variables;

    if (!earn || !cost) {
      throw new Error(
        `Could not find ${earn ? '' : `'earn',`}${
          cost ? '' : `'cost`
        } algorithm for '${cur.powerUpId}'`,
      );
      return res;
    }

    res[cur.powerUpId] = {
      earn,
      cost,
    };
  }

  return res;
}, {} as Foo);

interface EarnAlgoOpts {
  id: string;
  level: number;
  initialValue: number;
}

interface DecreaseFactorAlgoOpts extends EarnAlgoOpts {
  decreaseFactor: number;
  level1IncrementalEarnings: number;
  level2JumpEarnings: number;
  minimumEarnings: number;
}

interface AirdropAlgoOpts extends DecreaseFactorAlgoOpts {}

interface GiftOnlyAlgoOpts extends DecreaseFactorAlgoOpts {
  giftsMultiplier: number;
  levelsBetweenStepChange: number;
}

const earnAlgorithms: Record<string, (opts: EarnAlgoOpts) => number> = {
  default: ({ id, level, initialValue }: EarnAlgoOpts) => {
    let earn = initialValue;
    if (level > 0) {
      earn =
        earnAlgorithms.default({ id, level: level - 1, initialValue }) * 1.06;
    }
    if (earn >= 1_000) {
      return Math.ceil(earn / 10) * 10;
    }
    return Math.round(earn);
  },

  // used for gift only cards
  giftOnly: (opts) => {
    const config = bondingCurves[opts.id]?.cost;

    // @ts-ignore (it might be this type so we check if it's set)
    if (!config || config.levelsBetweenStepChange === undefined) {
      throw new Error(
        `Trying to get 'giftOnly', but no config is found '${opts.id}'.`,
      );
      return -1;
    }

    const { levelsBetweenStepChange, multiplier } =
      config as PUGiftOnlyCostCurve['variables'];
    return giftOnlyAlgorithms.default({
      ...opts,
      decreaseFactor: GO_DECREASE_FACTOR,
      level1IncrementalEarnings: GO_LEVEL1_INCREMENTAL_EARNINGS,
      level2JumpEarnings: GO_LEVEL2_JUMP_EARNINGS,
      giftsMultiplier: multiplier,
      levelsBetweenStepChange,
      minimumEarnings: GO_MINIMUM_EARNINGS,
    });
  },

  decreaseFactor: (opts) => {
    let sum = opts.initialValue;
    for (let i = 1; i < opts.level; i++) {
      sum += getIncrementalEarnRateWithDecreaseFactor({
        ...opts,
        level: i + 1,
        decreaseFactor: DF_DECREASE_FACTOR,
        level1IncrementalEarnings: DF_LEVEL1_INCREMENTAL_EARNINGS,
        level2JumpEarnings: DF_LEVEL2_JUMP_EARNINGS,
        minimumEarnings: DF_MINIMUM_EARNINGS,
      });
    }
    return sum;
  },

  polynomialEarn: ({ id, level }: EarnAlgoOpts) => {
    const config = bondingCurves[id]
      ?.earn as PUPolynomialEarnCurve['variables'];

    if (!config) {
      throw new Error(
        `Trying to get 'polynomialEarn', but no config is found '${id}'.`,
      );
      return -1;
    }

    const { constant, multiplier1, multiplier2, multiplier3 } = config;
    return Math.round(
      constant +
        multiplier1 * level +
        multiplier2 * Math.pow(level, 2) +
        multiplier3 * Math.pow(level, 3),
    );
  },
};

interface CostAlgoOpts {
  level: number;
  initialValue: number;
  tweakCoeff: number;
  state: State;
  id: string;
  now: number;
}

const costAlgorithms: Record<string, (opts: CostAlgoOpts) => number> = {
  default: (opts: CostAlgoOpts) => {
    let cost = opts.initialValue;
    if (opts.level === 1) {
      cost = opts.initialValue * 1.1;
    } else if (opts.level > 1) {
      cost = costAlgorithms.default({ ...opts, level: opts.level - 1 }) * 1.06;
    }
    return Math.round(cost);
  },
  withGiftDiscount: (opts: CostAlgoOpts) => {
    const normalCost = costAlgorithms.default(opts);
    const discount = getGiftDiscount(opts.state, opts.id);
    return normalCost - normalCost * (discount / 100);
  },
  polynomialCost: (opts: CostAlgoOpts) => {
    const { id, level, initialValue } = opts;
    if (level <= 1) {
      return initialValue;
    }

    const config = bondingCurves[id]?.cost;

    // @ts-ignore (it might be this type so we check if it's set)
    if (!config || config.levelsBetweenStepChange) {
      throw new Error(
        `Trying to get 'polynomialCost', but no config is found '${id}'.`,
      );
    }

    const { constant, multiplier1, multiplier2 } =
      config as PUPolynomialCostCurve['variables'];

    const lastLevelCost = costAlgorithms.polynomialCost({
      ...opts,
      level: level - 1,
    });

    return Math.round(
      (1 +
        (constant +
          multiplier1 * (level - 1) +
          multiplier2 * Math.pow(level - 1, 2)) *
          opts.tweakCoeff) *
        lastLevelCost,
    );
  },
};

const giftOnlyAlgorithms: Record<string, (opts: GiftOnlyAlgoOpts) => number> = {
  default: (opts: GiftOnlyAlgoOpts) => {
    let sum = opts.initialValue;
    for (let i = 1; i < opts.level; i++) {
      sum += getIncrementalEarnRateWithDecreaseFactor({
        ...opts,
        level: i + 1,
      });
    }
    return sum;
  },

  noOfGiftsAtLevel: (opts: GiftOnlyAlgoOpts) => {
    const num = Math.floor((opts.level - 1) / opts.levelsBetweenStepChange);
    return Math.pow(opts.giftsMultiplier, num);
  },

  maxTotalGiftsNeededAtLevel: (opts: GiftOnlyAlgoOpts) => {
    let sum = 0;
    for (let i = 1; i <= opts.level; i++) {
      sum += giftOnlyAlgorithms.noOfGiftsAtLevel({ ...opts, level: i });
    }
    return sum;
  },
};

function getIncrementalEarnRateWithDecreaseFactor(
  opts: DecreaseFactorAlgoOpts,
): number {
  if (opts.level === 0) {
    return 0;
  } else if (opts.level === 1) {
    return opts.initialValue * (opts.level1IncrementalEarnings / 100);
  } else if (opts.level === 2) {
    return Math.ceil(
      getIncrementalEarnRateWithDecreaseFactor({ ...opts, level: 1 }) *
        (1 + opts.level2JumpEarnings / 100),
    );
  } else {
    const prevLevelIncrementalEarnRate =
      getIncrementalEarnRateWithDecreaseFactor({
        ...opts,
        level: opts.level - 1,
      });
    const jumpInEarningsWithDecreaseFactor =
      (1 + opts.level2JumpEarnings / 100) *
      Math.pow(opts.decreaseFactor, opts.level - 1);
    const incrementalValue = Math.round(
      prevLevelIncrementalEarnRate * jumpInEarningsWithDecreaseFactor,
    );
    return Math.max(incrementalValue, opts.minimumEarnings);
  }
}

export function getNoOfGiftsAtLevel(level: number, id: string) {
  return giftOnlyAlgorithms.maxTotalGiftsNeededAtLevel(
    getGiftOnlyAlgoOpts(level, id),
  );
}

export function getGiftOnlyCardsReceived(state: State, id: string) {
  return state.powerUps.owned[id]?.giftsReceived || 0;
}

export function getGiftOnlyAlgoOpts(level: number, id: string) {
  const config = bondingCurves[id]?.cost;

  let levelsBetweenStepChange = -1;
  let multiplier = -1;

  // @ts-ignore (it might be this type so we check if it's set)
  if (!config || config.levelsBetweenStepChange === undefined) {
    throw new Error(
      `Trying to get 'giftOnly', but no config is found '${id}'.`,
    );
  } else {
    const cfg = config as PUGiftOnlyCostCurve['variables'];
    levelsBetweenStepChange = cfg.levelsBetweenStepChange;
    multiplier = cfg.multiplier;
  }

  return {
    id,
    decreaseFactor: GO_DECREASE_FACTOR,
    initialValue: GO_INITIAL_VALUE,
    level1IncrementalEarnings: GO_LEVEL1_INCREMENTAL_EARNINGS,
    level2JumpEarnings: GO_LEVEL2_JUMP_EARNINGS,
    giftsMultiplier: multiplier,
    levelsBetweenStepChange,
    minimumEarnings: GO_MINIMUM_EARNINGS,
    level,
  };
}

// applies only for gift only cards
export function getGiftsNeededForNextLevel(powerUpCard: PowerUp) {
  if (powerUpCard.type !== PowerUpCardType.GIFT_ONLY) {
    throw new Error('This function only applies to gift only cards');
  }

  return giftOnlyAlgorithms.maxTotalGiftsNeededAtLevel(
    getGiftOnlyAlgoOpts(powerUpCard.level + 1, powerUpCard.id),
  );
}

interface ConditionOpts {
  state: State;
  conditionCfg: ConditionCfg;
  powerUpCfg: PowerUpCfg;
}
const conditionsAlgorithms: Record<
  string,
  (opts: ConditionOpts) => string | undefined
> = {
  none: () => undefined,
  requirePowerUp: ({ state, conditionCfg }) => {
    if (!conditionCfg.targetPowerUp) {
      throw new Error(
        `Missing 'targetPowerUp' in config. (${conditionCfg.description})`,
      );
    }
    if (!Boolean(state.powerUps.owned[conditionCfg.targetPowerUp])) {
      return conditionCfg.description;
    }
  },
  requireLevel: ({ state, conditionCfg }) => {
    if (!conditionCfg.targetPowerUp || conditionCfg.targetLevel === undefined) {
      throw new Error(
        `Missing 'targetPowerUp' or 'targetLevel' in config. (${conditionCfg.description})`,
      );
    }

    // This fixes the issue of items becoming unlocked whrn targetLevel is equal than required conditon,
    // but becoming locked again when target level becomes greater than required condition
    // todo(Cai): changing original !== check below to be 'less than' causes all items to become unlocked for some reason.
    // todo(Cai): adding this new check is the way i found to make it work for now
    if (
      state.powerUps.owned[conditionCfg.targetPowerUp]?.level >=
      conditionCfg.targetLevel
    ) {
      return undefined;
    }

    if (
      state.powerUps.owned[conditionCfg.targetPowerUp]?.level !==
      conditionCfg.targetLevel
    ) {
      return conditionCfg.description;
    }

    return undefined;
  },
  requireFriendInvite: ({ state, conditionCfg }) => {
    if (conditionCfg.targetInvites === undefined) {
      throw new Error(
        `Missing 'targetInvites' in config. (${conditionCfg.description})`,
      );
    }

    if (state.friendCount < conditionCfg.targetInvites) {
      return conditionCfg.description;
    }

    return undefined;
  },
  requireEarnings: ({ state, conditionCfg }) => {
    if (!conditionCfg.targetEarnings) {
      throw new Error(
        `Missing 'targetEarnings' in config. (${conditionCfg.description})`,
      );
    }
    if (
      !conditionCfg.targetEarnings.every(
        (earning) => state.earnings[earning as keyof typeof state.earnings],
      )
    ) {
      return conditionCfg.description;
    }
    return undefined;
  },
  requireMoreFriendsFromLast: ({ state, conditionCfg, powerUpCfg }) => {
    if (!conditionCfg.targetInvites) {
      throw new Error(
        `Missing 'targetInvites' in config. (${conditionCfg.description})`,
      );
    }

    const specialsBasis = state.powerUps.specialsFriendBasis[powerUpCfg.id];
    const baseLevel = specialsBasis?.level;
    const baseFriendCount = specialsBasis?.friendCount;

    // not properly initialized, so just show locked message
    if (baseLevel === undefined || baseFriendCount === undefined) {
      return conditionCfg.description;
    }

    const myPowerUp = state.powerUps.owned[powerUpCfg.id];
    const myPowerUpLevel = myPowerUp?.level || 0;
    // if not matching, base level has not been properly sync'd yet
    if (myPowerUpLevel !== baseLevel) {
      return conditionCfg.description;
    }

    // if the current friend count is less than the expected friends required to unlock, show locked message
    if (state.friendCount < baseFriendCount + conditionCfg.targetInvites) {
      return conditionCfg.description;
    }

    return undefined;
  },

  requireGift: ({ state, powerUpCfg, conditionCfg }) => {
    if (!conditionCfg.targetPowerUp) {
      throw new Error(
        `Missing 'targetPowerUp' in config. (${conditionCfg.description})`,
      );
    }

    const myPowerUp = state.powerUps.owned[powerUpCfg.id];
    // if already owned, then no other checking is required
    if (myPowerUp) {
      return undefined;
    }

    const conditionKey = conditionCfg.targetPowerUp;
    let condition;
    if (powerUpCfg.type === PowerUpCardType.GIFT_DAILY_WITH_DISCOUNT) {
      condition = state.powerUps.conditions.gift_daily_with_discount[
        conditionKey
      ] as PUDailyLevelUserCondition;
    } else if (powerUpCfg.type === PowerUpCardType.GIFT_ONLY) {
      condition = state.powerUps.conditions.gift_only[
        conditionKey
      ] as PUDailyLevelUserCondition;
    }

    if (!condition) {
      throw new Error(
        `Could not find condition state in player's state for '${conditionKey}'.`,
      );
    }

    if (condition.dailyGifts.length > 0) {
      return undefined;
    }
    return conditionCfg.description;
  },
};

const getSpecialPowerUpState = ({
  state,
  powerUpCfg,
  now,
}: {
  state: State;
  powerUpCfg: PowerUpCfg;
  now: number;
}): SpecialPowerUpState => {
  if (powerUpCfg.category !== 'Specials') {
    // not applicable
    return undefined;
  }

  powerUpCfg.id === PU_SPECIALS_DAO_ITEM_ID;

  if (powerUpCfg.expireTime && now >= powerUpCfg.expireTime) {
    return 'Missed';
  }

  const myPowerUp = state.powerUps.owned[powerUpCfg.id];
  if (myPowerUp) {
    // show as owned
    return 'Owned';
  }

  if (powerUpCfg.availability?.startTime) {
    // availability only valid if we have a startTime
    if (now > powerUpCfg.availability.endTime) {
      return 'Missed';
    }
    if (now >= powerUpCfg.availability.startTime) {
      return 'Available';
    }

    // before startTime, so not yet available
    return undefined;
  }

  return 'Available';
};

interface SpamControl {
  timeout?: NodeJS.Timeout;
  count: number;
}

interface ErrorSpamControl {
  earn?: SpamControl;
  cost?: SpamControl;
  condition?: SpamControl;
}

const errorSpamControl: ErrorSpamControl = {
  earn: undefined,
  cost: undefined,
  condition: undefined,
};
const spamKeys = Object.keys(
  errorSpamControl,
) as (keyof typeof errorSpamControl)[];
const getSpamControlKey = (
  err: string,
): keyof typeof errorSpamControl | undefined => {
  for (let i = 0; i < spamKeys.length; i++) {
    if (err.includes(spamKeys[i])) {
      return spamKeys[i];
    }
  }
  return undefined;
};

const BASE_BACKOFF = 30;

export const getPowerUps = (state: State, now: number): PowerUp[] => {
  const powerUpsCfgs = airtableCfgToPowerUpCfgs(
    airtablePowerUps,
    airtableConditions,
  );

  return powerUpsCfgs
    .map((cfg) => {
      try {
        return getPowerUpFromCfg(cfg, state, now);
      } catch (e: any) {
        const spamKey = getSpamControlKey(e.message);

        if (spamKey && !errorSpamControl[spamKey]?.timeout) {
          console.error(e);
          if (!errorSpamControl[spamKey]) {
            errorSpamControl[spamKey] = {
              count: 0,
              timeout: setTimeout(() => {
                clearInterval(errorSpamControl[spamKey]!.timeout);
                errorSpamControl[spamKey]!.timeout = undefined;
              }, BASE_BACKOFF * 1000),
            };
          } else {
            const count = errorSpamControl[spamKey]!.count + 1;
            errorSpamControl[spamKey] = {
              count,
              timeout: setTimeout(() => {
                clearInterval(errorSpamControl[spamKey]!.timeout);
                errorSpamControl[spamKey]!.timeout = undefined;
              }, BASE_BACKOFF * (count * 2) * 1000),
            };
          }
        }

        return undefined;
      }
    })
    .filter(Boolean)
    .sort((a, b) => {
      const badges = state.badgeControl.cards;
      // put badges on top always
      const aHasBadge = badges.find((badge) => badge.id === a?.id);
      const bHasBadge = badges.find((badge) => badge.id === b?.id);
      if (aHasBadge && !bHasBadge) {
        return -1;
      }

      if (!aHasBadge && bHasBadge) {
        return 1;
      }
      // put expired items as last
      const aExpired = Boolean(a?.expireTime && now >= a.expireTime);
      const bExpired = Boolean(b?.expireTime && now >= b.expireTime);
      if (aExpired && !bExpired) {
        return 1;
      }
      if (!aExpired && bExpired) {
        return -1;
      }
      return 0;
    }) as PowerUp[];
};

export const getGiftPowerUps = () => {
  return airtablePowerUps.filter(
    (powerUpItem) =>
      powerUpItem.type === PowerUpCardType.GIFT_DAILY_WITH_DISCOUNT ||
      powerUpItem.type === PowerUpCardType.GIFT_ONLY,
  );
};

// note: this only checks if the card is active based on the startTime and endTime. The card becomes completely locked past its endTime.
// With expireTime the card can still be shared and i think leveled up??
export const cardActive = (card: string, now: number) => {
  const cardCfg = airtablePowerUps.find((cfg) => cfg.id === card);
  if (!cardCfg) {
    return false;
  }
  if (cardCfg.startTime && cardCfg.endTime) {
    return now >= cardCfg.startTime && now <= cardCfg.endTime;
  }
  return false;
};

export const getActivePowerUpById = (
  puId: string,
  now?: number,
): PowerUpAirtableItem | undefined => {
  if (now) {
    const tmp = airtablePowerUps.find((pu) => pu.id === puId);
    return tmp && cardActive(tmp.id, now) ? tmp : undefined;
  } else {
    return airtablePowerUps.find((pu) => pu.id === puId);
  }
};

export const getPowerUpFromCfg = (
  cfg: PowerUpCfg,
  state: State,
  now: number,
): PowerUpUI => {
  const earnKey = cfg.earnAlgorithm ? cfg.earnAlgorithm : 'polynomialEarn';
  const getEarn = earnAlgorithms[earnKey];
  const isGiftOnly = cfg.type === PowerUpCardType.GIFT_ONLY;
  if (!getEarn) {
    throw new Error(
      `Could not find earn algorithm for '${cfg.earnAlgorithm}'. (${cfg.id})`,
    );
  }
  const costKey = cfg.costAlgorithm ? cfg.costAlgorithm : 'polynomialCost';
  const getCost = costAlgorithms[costKey];
  if (!getCost) {
    throw new Error(
      `Could not find cost algorithm for '${cfg.costAlgorithm}'. (${cfg.id})`,
    );
  }
  const validateConditions = cfg.conditions.every(
    (condition) => conditionsAlgorithms[condition.key],
  );

  if (!validateConditions) {
    throw new Error(
      `One or more condition keys could not match valid algorithms. (${cfg.id})`,
    );
  }

  const myPowerUp = state.powerUps.owned[cfg.id];

  const level = myPowerUp?.level || 0;

  const curveLevel = level || cfg.curveLevel;

  // @TODO: We need to provide the reason why it's locked
  let lockedMessage = undefined;
  let isGift = false;
  let isFriendGated = false;

  if (cfg.expireTime && now >= cfg.expireTime) {
    lockedMessage = 'Expired';
  } else {
    // We should only check for locked if we haven't got it yet
    for (let i = 0; i < cfg.conditions.length; i++) {
      const condition = cfg.conditions[i];
      if (condition.key === 'requireGift') {
        isGift = true;
      } else if (condition.key === 'requireMoreFriendsFromLast') {
        isFriendGated = true;
      }
      const getUnlocked = conditionsAlgorithms[condition.key];
      lockedMessage = getUnlocked({
        state,
        conditionCfg: condition,
        powerUpCfg: cfg,
      });
      if (lockedMessage) {
        break;
      }
    }
  }

  let specialState: SpecialPowerUpState = getSpecialPowerUpState({
    state,
    powerUpCfg: cfg,
    now,
  });

  const badges = state.badgeControl.cards;
  const hasBadge = Boolean(badges.find((badge) => badge.id === cfg.id));

  let tweakCoeff = 1.0;

  return {
    id: cfg.id,
    category: cfg.category,
    maxLevel: cfg.maxLevel,
    details: cfg.details,
    earn: getEarn({
      id: cfg.id,
      level: curveLevel,
      initialValue: cfg.initialEarn,
    }),
    cost: isGiftOnly
      ? 0
      : getCost({
          level: curveLevel,
          initialValue: cfg.initialCost,
          state,
          id: cfg.id,
          now,
          tweakCoeff,
        }),
    level,
    locked: lockedMessage,
    specialState,
    endTime: cfg.availability?.endTime,
    startTime: cfg.availability?.startTime,
    expireTime: cfg.expireTime,
    isGift,
    isFriendGated,
    sort: hasBadge ? 1 : cfg.sort,
    type: cfg.type,
    hasBadge,
  };
};

export const getPowerUpIdsWithConditionKey = (
  conditionKey: string,
): string[] => {
  const conditionCfgIds = airtableConditions
    .filter((cfg) => cfg.key === conditionKey)
    .map((cfg) => cfg.id);
  const powerCfgIds = airtablePowerUps
    .filter((cfg) =>
      cfg.condition
        ?.split(',')
        .map((k) => k.trim())
        .some((cId) => conditionCfgIds.includes(cId)),
    )
    .map((cfg) => cfg.id);
  return powerCfgIds;
};

export function getPowerUpBonusPerHour(
  state: State,
  now: number,
  excludeExpirable = false,
): number {
  const powerUps = getPowerUps(state, now);
  let totalBonusPerHour = 0;
  Object.keys(state.powerUps.owned).forEach((puId) => {
    const powerUp = powerUps.find((pu) => pu.id === puId);
    // only add to total if non-expiring or has not expired yet. if excluding expirables, don't add if an expirable.
    if (
      !powerUp?.expireTime ||
      (!excludeExpirable && now < powerUp.expireTime)
    ) {
      totalBonusPerHour += powerUp?.earn || 0;
    }
  });
  return Math.round(totalBonusPerHour);
}

export function getPowerUpBonusPerSecond(
  state: State,
  now: number,
  excludeExpirable?: boolean,
): number {
  return (
    (getPowerUpBonusPerHour(state, now, excludeExpirable) / HOUR_IN_MS) * 1000
  );
}

export function getExpirablePowerUpBonus(state: State, now: number): number {
  const powerUps = getPowerUps(state, now);
  let totalBonus = 0;
  const maxPossibleMiningDuration = Math.min(
    now - state.powerUps.last_claimed,
    PU_BONUS_MAX_MINING_DURATION,
  );
  Object.keys(state.powerUps.owned).forEach((puId) => {
    const powerUp = powerUps.find((pu) => pu.id === puId);

    if (!powerUp?.expireTime) {
      // not expirable, so skip
      return;
    }

    if (state.powerUps.last_claimed >= powerUp.expireTime) {
      // past expiration, so skip
      return;
    }

    /*
    determine mining duration by picking the lowest of:
    - max possible mining duration
    - time between last claim time and expiration time
    - time between last claim time and now
    */
    const miningDuration = Math.min(
      maxPossibleMiningDuration,
      powerUp.expireTime - state.powerUps.last_claimed,
      now - state.powerUps.last_claimed,
    );

    const earn = powerUp.earn || 0;

    // add to total bonus
    totalBonus += (earn / HOUR_IN_MS) * miningDuration;
  });

  return Math.round(totalBonus);
}

function validateCategory(value: string) {
  return (
    value === 'Gear' ||
    value === 'Services' ||
    value === 'Companions' ||
    value === 'Specials'
  );
}

function airtableConditionToConditionCfg(
  condition: ConditionAirtableItem,
): ConditionCfg {
  return {
    key: condition.key,
    description: condition.description,
    targetPowerUp: condition.targetPowerUp,
    targetLevel: condition.targetLevel,
    targetInvites: condition.targetInvites,
    targetEarnings: condition.targetEarnings
      ? condition.targetEarnings.split(',').map((c) => c.trim())
      : undefined,
  };
}

export function airtableCfgToPowerUpCfgs(
  airtableItems: PowerUpAirtableItem[],
  conditions: ConditionAirtableItem[],
): PowerUpCfg[] {
  return airtableItems
    .map((item) => {
      if (!validateCategory(item.category)) {
        return undefined;
      }

      const baseData: PowerUpCfg = {
        id: item.id,
        type: item.type,
        category: item.category as PowerUpCategory,
        details: {
          name: item.name,
          description: item.description,
          image: item.image || '',
        },
        curveLevel: item.curveLevel || 0,
        initialEarn: item.initialEarn,
        earnAlgorithm: item.earnAlgorithm,
        initialCost: item.initialCost,
        costAlgorithm: item.costAlgorithm,
        expireTime: item.expireTime,
        conditions: [],
        sort: item.sort,
        maxLevel: item.maxLevel,
      };

      if (item.condition) {
        const conditionIds = item.condition.split(',').map((c) => c.trim());

        conditionIds.forEach((id) => {
          const condition = conditions.find((c) => c.id === id);
          if (condition) {
            baseData.conditions.push(
              airtableConditionToConditionCfg(condition),
            );
          }
        });
      }

      if (item.startTime && item.endTime) {
        baseData.availability = {
          startTime: item.startTime,
          endTime: item.endTime,
        };
      }

      return baseData;
    })
    .filter(Boolean) as PowerUpCfg[];
}

export const isDailyPowerUp = (id: string, now: number) => {
  const today = getDayMidnightInUTC(now, 0);
  const tomorrow = getDayMidnightInUTC(now, 1);

  let daily: PowerUpDailyAirtableItem | undefined;
  for (let i = 0; i < airtableDailies.length; i++) {
    const current = airtableDailies[i];
    if (current.starts_at >= today && current.starts_at < tomorrow) {
      daily = current;
      break;
    }
  }

  // console.log('isDailyPowerUp', {daily});

  const dailyPowerUps = daily
    ? [daily.power_up_1, daily.power_up_2, daily.power_up_3]
    : [];

  return dailyPowerUps.includes(id);
};

export function getTotalDailyBonusReward(state: State) {
  return state.powerUps.daily.power_ups.length === 3
    ? PU_DAILY_BONUS_REWARD
    : 0;
}

export function getEarnDiff(id: string, state: State, now: number) {
  const powerUps = getPowerUps(state, now);
  const targetPowerUp = powerUps.find((pu) => pu.id === id);
  if (!targetPowerUp) {
    return 0;
  }
  const cfg = airtablePowerUps.find((cfg) => cfg.id === id);
  if (!cfg) {
    return 0;
  }
  const getEarn = earnAlgorithms[cfg?.earnAlgorithm || 'polynomialEarn'];
  if (!getEarn) {
    return 0;
  }
  const next = getEarn({
    id,
    level: targetPowerUp.level,
    initialValue: cfg.initialEarn,
  });
  if (targetPowerUp.level === 0) {
    return next;
  }
  const prev = getEarn({
    id,
    level: targetPowerUp.level - 1,
    initialValue: cfg.initialEarn,
  });
  const earnDiff = next - prev;
  return earnDiff > 0 ? earnDiff : next;
}

export function getPowerUpById(state: State, now: number, id: string) {
  const powerUps = getPowerUps(state, now);
  return powerUps.find((pu) => pu.id === id);
}

export function getGiftMessage(state: State, giftName: string | undefined) {
  return `${state.username} sent you a free gift.\nOpen now to claim it.`;
}

export function getGiftDiscount(state: State, puId: string) {
  const conditionState = state.powerUps.conditions.gift_daily_with_discount[
    puId
  ] as PUDailyLevelUserCondition;

  return (
    Math.min(conditionState.discountList.length, MAX_GIFT_FRIENDS) *
    DISCOUNT_PCT_PER_FRIEND
  );
}

export function hasReachedMaxDiscount(state: State, puId: string) {
  return (
    getGiftDiscount(state, puId) === MAX_GIFT_FRIENDS * DISCOUNT_PCT_PER_FRIEND
  );
}

export function hasReceivedFromUserToday(
  state: State,
  card: PowerUpAirtableItem,
  userId: string,
) {
  let conditionState;
  if (card.type === PowerUpCardType.GIFT_DAILY_WITH_DISCOUNT) {
    conditionState = state.powerUps.conditions.gift_daily_with_discount[
      card.id
    ] as PUDailyLevelUserCondition;
  } else if (card.type === PowerUpCardType.GIFT_ONLY) {
    conditionState = state.powerUps.conditions.gift_only[
      card.id
    ] as PUDailyLevelUserCondition;
  } else {
    return false;
  }

  return conditionState.dailyGifts.includes(userId);
}

export function getOwnMemeGiftId(memeId: string, shareTime: number) {
  return `${memeId}.${shareTime}`;
}

export function getUserMemeGiftId(
  userId: string,
  memeId: string,
  shareTime: number,
) {
  return `${userId}.${memeId}.${shareTime}`;
}

export function hasReceivedUserMemeGift(
  state: State,
  userId: string,
  memeId: string,
  shareTime: number,
) {
  const giftId = getUserMemeGiftId(userId, memeId, shareTime);
  const claimedGiftSharedTime = state.trading.userMemeGiftsClaimed[giftId];

  const hasReceivedFromUserToday = Object.keys(
    state.trading.userMemeGiftsClaimed,
  ).some((giftId) => {
    const [claimedFrom] = giftId.split('.');
    return claimedFrom === userId;
  });

  const hasReceived =
    hasReceivedFromUserToday || Boolean(claimedGiftSharedTime);

  return hasReceived;
}

export function getCanReceiveDiscount(
  state: State,
  puId: string,
  userId: string,
) {
  const conditionState = state.powerUps.conditions.gift_daily_with_discount[
    puId
  ] as PUDailyLevelUserCondition;

  if (!conditionState) {
    return false;
  }

  if (conditionState.discountList.length >= MAX_GIFT_FRIENDS) {
    return false;
  }
  // If the user is not in the discount list, we can add to discount list
  return !conditionState.discountList.includes(userId);
}

/*
 * Get owned power up statistics. It contains:
 * - Unique count (no levels)
 * - Total count (with levels)
 * - Bonus per hour
 * - Gear cards unique count
 * - Companion (Worker) cards unique count
 * - Service cards unique count
 * - Special cards unique count
 *
 * @param state
 * @param now
 */
export function getOwnedPowerUpsStats(
  state: State,
  now: number,
  includeExpired = false,
): {
  uniqueCount: number;
  totalCount: number;
  bonusPerHour: number;
  gearUniqueCount: number;
  companionUniqueCount: number;
  serviceUniqueCount: number;
  specialUniqueCount: number;
} {
  /*
  We could technically do this separately, but power up lookup on user's owned
  power ups is potentially O(n*n), so it's combined to avoid having to do that
  more than once.

  Looking up owned power up per power up config ID is O(n), but
  the power up config list can be really huge.
  */
  /*
    value === 'Gear' ||
    value === 'Services' ||
    value === 'Companions' ||
    value === 'Specials'
  */
  const ownedPus = Object.keys(state.powerUps.owned).reduce(
    (acc, puId) => {
      const powerUp = getPowerUpById(state, now, puId);
      if (!powerUp) {
        return acc;
      }

      const expired = powerUp.expireTime && now < powerUp.expireTime;
      if (!includeExpired && expired) {
        return acc;
      }

      if (powerUp.category === 'Gear') {
        acc.gearUniqueCount += 1;
      } else if (powerUp.category === 'Companions') {
        acc.companionUniqueCount += 1;
      } else if (powerUp.category === 'Services') {
        acc.serviceUniqueCount += 1;
      } else if (powerUp.category === 'Specials') {
        acc.specialUniqueCount += 1;
      }

      acc.bonusPerHour += powerUp.earn || 0;
      acc.uniqueCount += 1;
      acc.totalCount += state.powerUps.owned[puId].level || 0;

      return acc;
    },
    {
      uniqueCount: 0,
      totalCount: 0,
      bonusPerHour: 0,
      gearUniqueCount: 0,
      companionUniqueCount: 0,
      serviceUniqueCount: 0,
      specialUniqueCount: 0,
    },
  );

  return {
    ...ownedPus,
  };
}

export function getPowerUpAvailableTime(
  state: State,
  powerUp: PowerUp,
): number {
  const isSpecial = powerUp.specialState !== undefined;
  if (isSpecial) {
    const ownedPowerUp = state.powerUps.owned[powerUp.id];
    const level = ownedPowerUp?.level ?? 0;
    const lastClaimed = ownedPowerUp?.lastClaimed ?? 0;
    return lastClaimed + specialCardWaitTimes[level];
  }

  return 0;
}

export function getMiningRevenues(state: State, now: number, since: number) {
  // exclude expirables since we calculate that separately
  const powerUpRewardPerHour = getPowerUpBonusPerHour(state, now, true);
  const expirableBonus = getExpirablePowerUpBonus(state, now);

  let reward = 0;

  if (powerUpRewardPerHour > 0) {
    const miningTime = Math.min(now - since, PU_BONUS_MAX_MINING_DURATION);
    reward += Math.round((powerUpRewardPerHour / HOUR_IN_MS) * miningTime);
  }

  if (expirableBonus > 0) {
    reward += expirableBonus;
  }

  return reward;
}
