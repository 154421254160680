import { t } from 'i18next';
import { assets } from '../../assets/assets';
import {
  addClickerPointUp,
  addMorseUp,
} from '../../components/pages/ClickerPage/ClickerDiamond/addPointUp';
import { getReferralUrl } from '../../replicant/features/chatbot/chatbot.private';
import {
  getLeague,
  getEnergy,
  getPointsPerTap,
  getDailyCode,
} from '../../replicant/features/game/game.getters';
import { DAILY_CODE_PRIZE } from '../../replicant/features/game/ruleset/dailyRewards';
import { League } from '../../replicant/features/game/ruleset/league';
import {
  getActivePowerUpById,
  getGiftMessage,
  getPowerUps,
} from '../../replicant/features/powerups/getters';
import { isExpectedError } from '../../replicant/types';
import { gameApi } from '../api';
import { boosterConfig } from '../consts';
import { morseTimes, morseCodeAlphabet } from '../morseCode';
import {
  Team,
  PlayerGame,
  PromoCardInitInfo,
  GiftCardInitInfo,
  Booster,
  Buff,
  LeagueLeaderboard,
} from '../types';
import { BusinessController } from './BusinessController';
import { UIEvents, ElementUIState } from './UIController/UITypes';
import { Timer, TimerEvents } from '../Timer';
import { getBotName } from '../config';

export enum ClickerEvents {
  onMyTeamUpdate = 'onMyTeamUpdate',
  onGameStateUpdate = 'onGameStateUpdate',
  onRocketmanChange = 'onRocktmanStart',
  onBuffBought = 'onBuffBought',
  onAdEnergyRewardChange = 'onAdEnergyRewardUpdate',
  onAppModeChange = 'onAppModeChange',
}

interface InitOpts {
  botEarnings?: number;
  inviteDrawerDuration: number;
  powerUpBonus: number;
  unclaimedReferralRewards: number;
}

/**
 * This class is just to split off the things that are related to the clicker from
 * AppController where everything used to be at first.
 */
export class ClickerController extends BusinessController<
  ClickerEvents,
  InitOpts
> {
  private isRocketmanActive = false;

  private _playerTeam?: Team;
  public get playerTeam() {
    return this._playerTeam;
  }

  private player?: PlayerGame;
  public get energyLimit() {
    return this.player?.energyLimit || 0;
  }

  public get league() {
    return getLeague(this.app.replicant?.state) as League;
  }

  private _botEarnings?: number;
  public get botEarnings() {
    return this._botEarnings;
  }

  private _powerUpBonus = 0;
  public get powerUpBonus() {
    return this._powerUpBonus;
  }

  private _unclaimedReferralRewards = 0;
  public get unclaimedReferralRewards() {
    return this._unclaimedReferralRewards;
  }

  private _inviteDrawerDuration = 0;
  public get inviteDrawerDuration() {
    return this._inviteDrawerDuration;
  }

  private _cardPromo?: PromoCardInitInfo;
  public get cardPromo() {
    return this._cardPromo;
  }

  private _cardGift?: GiftCardInitInfo;
  public get cardGift() {
    return this._cardGift;
  }

  private _expiredGift?: string;
  public get expiredGift() {
    return this._expiredGift;
  }

  public morseCodeMode = false;
  private morseCode = '';
  private morseTimer?: NodeJS.Timeout;
  public morseWord = '';
  public morseWin = false;
  private hasTappedMorseOnce = false;

  private trackedTaps = 0;

  private timer = new Timer({
    id: 'Clicker',
    duration: Infinity,
    countType: 'up',
  });

  private searchTrackedThisSession = false;

  public get bonus() {
    if (
      !this.isRocketmanActive ||
      !this.player ||
      !this.player?.rocketmanDuration
    ) {
      return undefined;
    }

    const elapsedTimeInMs =
      this.player.lastRocketmanStart +
      this.player.rocketmanDuration -
      this.app.now();

    return {
      timeLeft: elapsedTimeInMs,
      multiplier: this.player.rocketmanMultiplier,
    };
  }

  private get playerId() {
    return this.app.state.id;
  }

  init = async (session: InitOpts) => {
    this._botEarnings = session.botEarnings;
    this._inviteDrawerDuration = session.inviteDrawerDuration;
    this._powerUpBonus = session.powerUpBonus;
    this._unclaimedReferralRewards = session.unclaimedReferralRewards;
    this.timer.addEventListener(TimerEvents.OnTick, this.onSecondElapsed);
    this.timer.start();
    this.onInitComplete();
  };

  trackTaps(taps: number) {
    this.trackedTaps += taps;
    return this.trackedTaps;
  }

  onTap = () => {
    if (!this.player) {
      console.error(`Cannot do taps without player set`);
      return;
    }

    if (this.app.tutorial.step?.onClickerTap) {
      this.app.tutorial.step.onClickerTap();
    }

    if (getEnergy(this.app.state, this.app.now()) > 0) {
      this.app.replicant?.invoke.tap();
    }

    // @note: needs to be done after the tap
    const isOutOfEnergy = getEnergy(this.app.state, this.app.now()) < 1;
    if (isOutOfEnergy) {
      this.app.tutorial.step?.onAction?.('outOfEnergy');
      this.app.ui.drawer.show({
        id: 'outOfEnergy',
        opts: {
          title: '',
          subtitle: '',
          buttons: [],
        },
      });
      return;
    }

    const pointsPerTap = getPointsPerTap(this.app.state, this.app.now()); // todo: this.app.now() might have been cause of desync

    this.app.tutorial.updateStepTapTrack(pointsPerTap);

    this.sendEvents(ClickerEvents.onGameStateUpdate);

    this.app.ui.onAppValueUpdate(UIEvents.OnBalanceUpdate);

    addClickerPointUp(pointsPerTap, Boolean(this.bonus));
  };

  joinTeam = async (teamId: string) => {
    const response = await gameApi.joinTeam(this.playerId, teamId);

    this.setPlayerTeam(response.team);
    this.setPlayer(response.player);
    this.sendEvents(ClickerEvents.onMyTeamUpdate);
    this.app.nav.goToHomePage();
  };

  leaveTeam = async () => {
    const response = await gameApi.leaveTeam(this.playerId);

    this.setPlayerTeam(undefined);
    this.setPlayer(response.player);
    this.sendEvents(ClickerEvents.onMyTeamUpdate);
    this.app.nav.goToHomePage();
    this.app.tutorial.step?.onAction &&
      this.app.tutorial.step?.onAction('leaveTeam');
  };

  // Gets called every second
  private onSecondElapsed = () => {
    this.sendEvents(ClickerEvents.onGameStateUpdate);

    if (this.bonus && this.bonus.timeLeft <= 0) {
      this.setRocketman(false);
    }
  };

  public setPlayer = (player: PlayerGame, isStart = false) => {
    this.player = player;
    if (!this.isRocketmanActive && player.rocketmanMultiplier > 1) {
      this.setRocketman(true);
    }
  };

  public setPlayerTeam = (team?: Team) => {
    this._playerTeam = team;
    this.sendEvents(ClickerEvents.onMyTeamUpdate);
  };

  private setRocketman = (start: boolean) => {
    this.isRocketmanActive = start;
    this.sendEvents(ClickerEvents.onRocketmanChange);
  };

  buyBooster = async (booster: Booster) => {
    const response = await gameApi.buyBooster(this.playerId, booster);
    if (isExpectedError(response)) {
      return response;
    }
    this.app.views.Toast.setData({ text: t(boosterConfig[booster].name) });
    this.setPlayer(response.player, true);
    this.app.views.Shop.fetch();
    this.app.views.Toast.show(false);
    return response.player.balance;
  };

  buyBuff = async (buff: Buff) => {
    const response = await gameApi.buyBuff(this.playerId, buff);
    if (isExpectedError(response)) {
      return response;
    }
    this.app.views.Toast.setData({ text: t(boosterConfig[buff].name) });
    this.app.nav.goToHomePage();
    this.setPlayer(response.player, true);
    this.app.views.Shop.fetch();
    this.app.views.Toast.show(false);
    if (buff === Buff.Rocketman && this.app.tutorial.step?.onAction) {
      this.app.tutorial.step?.onAction('rocketman');
    }
  };

  fetchLeaguePageData = async (league: League) => {
    const res = await gameApi.getLeagueLeaderboard(league, this.playerId);
    return res as LeagueLeaderboard;
  };

  getMineGiftUrl(payloadKey: string, card: string): string {
    const referralUrl = getReferralUrl(payloadKey, getBotName());
    // always english name since gift message is english
    const cardName = getActivePowerUpById(card)?.name;

    const url = encodeURIComponent(referralUrl);
    const text = encodeURIComponent(getGiftMessage(this.app.state, cardName));

    return `https://t.me/share/url?url=${url}&text=${text}`;
  }

  private getSortedGifts = () => {
    return getPowerUps(this.app.replicant?.state, this.app.now())
      .filter(
        (item) =>
          item.isGift &&
          item.endTime !== undefined &&
          item.startTime !== undefined &&
          this.app.now() < item.endTime &&
          this.app.now() >= item.startTime,
      )
      .sort((a, b) => (b.startTime ?? 0) - (a.startTime ?? 0));
  };

  getDailyAirdrop = () => {
    return this.getSortedGifts()[0];
  };

  getMultipleGifts = (amount: number) => {
    return this.getSortedGifts().slice(0, amount);
  };

  showLeagueLeaderboad = () => {
    this.app.views.LeaguePage.fetch(this.league);
    this.app.nav.goTo('LeaguePage');
  };

  initPowerUpSpecials = async () => {
    await this.app.replicant?.invoke.initPowerUpSpecials();
    this.sendEvents(ClickerEvents.onGameStateUpdate);
  };

  /**
   * Searches team by name. If `name` is empty or just spaces, this will return undefined.
   * @param name The search term.
   * @returns array of Team, or undefined if `name` is empty or just spaces.
   */
  searchTeams = async (name: string): Promise<Team[] | undefined> => {
    if (!this.app.replicant) {
      return undefined;
    }

    const trimmedName = name.trim();
    if (!trimmedName) {
      return undefined;
    }

    if (!this.searchTrackedThisSession && trimmedName.length >= 3) {
      this.app.track('SearchTeamInSession', {
        feature: 'search',
        $subfeature: 'search_team',
        originFeature: 'team',
        originSubFeature: 'team_view',
      });
      this.searchTrackedThisSession = true;
    }

    const teams = await this.app.replicant?.asyncGetters.searchTeams({
      searchString: trimmedName,
    });
    return teams as Team[];
  };

  onMorseInput = (code: '-' | '.') => {
    if (this.morseWin) {
      return;
    }
    addMorseUp(code);
    clearInterval(this.morseTimer);
    this.morseCode += code;
    this.morseTimer = setTimeout(() => {
      this.app.ui.setClickerUIState({ btnClicker: ElementUIState.Inactive });
      this.verifyMorseCode();

      // telemetry when user taps morse code for the first time in the session
      if (!this.hasTappedMorseOnce) {
        this.app.track('tap_morse', {});
        this.hasTappedMorseOnce = true;
      }
    }, morseTimes.codeEndDelay);
  };

  private verifyMorseCode = async () => {
    const code = morseCodeAlphabet[this.morseCode];
    const dailyCode = getDailyCode(this.app.now());
    if (dailyCode && code !== undefined) {
      const expectedLetter = dailyCode[this.morseWord.length];
      const isCorrect = code.toLowerCase() === expectedLetter.toLowerCase();
      if (isCorrect) {
        this.morseWord += code;
        // telemetry when user successfully adds one letter to the code
        this.app.track('tap_letter', {});
      } else {
        this.morseWord = '';
      }
      const correctWord =
        this.morseWord.toLowerCase() === dailyCode.toLowerCase();

      if (correctWord) {
        const confirmed = await this.app.invoke.onDailyCodeComplete({
          code: this.morseWord,
        });

        if (confirmed) {
          this.morseWin = true;

          this.app.ui.setClickerUIState({
            btnClicker: ElementUIState.Inactive,
            btnDailyCode: ElementUIState.Complete,
          });

          this.toggleDailyCodeActive(true);
          this.app.ui.confetti.show();
          this.morseCodeMode = false;

          this.app.ui.drawer.show({
            id: 'generic',
            opts: {
              image: assets.sloth_hacker,
              title: 'You win',
              subtitle: 'you solved the code',
              buttons: [
                {
                  cta: 'claim prize',
                  onClick: () => {
                    this.app.ui.animateBalance(DAILY_CODE_PRIZE);
                    this.app.ui.confetti.hide();
                    this.app.ui.drawer.close();
                  },
                },
              ],
            },
            onClose: () => {
              this.app.ui.confetti.hide();
            },
          });

          // telemetry when user successfully solves the code
          this.app.track('code_solved', {});
        } else {
          // Handle wrong server response
        }
      }
    }
    setTimeout(() => {
      this.morseCode = '';
      this.app.ui.setClickerUIState({ btnClicker: ElementUIState.Normal });
    }, 100);
  };

  toggleDailyCodeActive = (forceOff = false) => {
    // Trying to turn off but it's already off
    const isAlreadyOff = !this.morseCodeMode && forceOff;
    const isDisabled =
      this.app.ui.state.clicker.dailyCode === ElementUIState.Disabled;
    if ((isDisabled && !forceOff) || isAlreadyOff) {
      return;
    }

    this.morseCodeMode = forceOff ? false : !this.morseCodeMode;
    this.morseCode = '';
    this.morseWord = '';
    this.app.ui.setClickerUIState({
      dailyCode: this.morseCodeMode
        ? ElementUIState.Normal
        : ElementUIState.Remove,
    });

    this.sendEvents(ClickerEvents.onAppModeChange);

    // telemetry when user activates or deactivates the daily code mode
    if (this.morseCodeMode) {
      this.app.track('activate_daily_code', {
        feature: 'daily_code',
        $subfeature: 'daily_code_morse',
        originFeature: 'home',
      });
    } else {
      this.app.track('deactivate_daily_code’', {
        feature: 'daily_code',
        $subfeature: 'daily_code_morse',
        originFeature: 'home',
      });
    }
  };

  public setCardGift = (card?: GiftCardInitInfo) => {
    this._cardGift = card;
  };

  public setExpiredGift = (gift?: string) => {
    this._expiredGift = gift;
  };

  public setCardPromo = (card?: PromoCardInitInfo) => {
    this._cardPromo = card;
  };
}
