import { UISchema } from '@play-co/debug-panel';

import { app } from './Controllers/AppController';
import { waitFor } from './utils';
import { generatePromoLink } from '../data/sharing';
import { ModalLabels } from '../replicant/ruleset';

async function addPoints(points: number, delay = 1) {
  await app.replicant?.invoke.cheat_AddPoints({ points });
  // await new Promise((resolve) => setTimeout(resolve, delay * 1000));
  // window.location.reload();
}

async function setTaps(taps: number, delay = 1) {
  await app.replicant?.invoke.cheat_SetTaps({ taps });
  // await new Promise((resolve) => setTimeout(resolve, delay * 1000));
  // window.location.reload();
}

async function setBalance(balance: number, delay = 0) {
  await app.replicant?.invoke.cheat_SetBalance({ balance });
  // await new Promise((resolve) => setTimeout(resolve, delay * 1000));
  // window.location.reload();
}

async function addFakeFriend(numOfFriends: number = 1, reset = false) {
  await app.replicant?.invoke.cheat_AddFakeFreinds({
    friends: numOfFriends,
    reset,
  });
}

async function cleanEarnPageQuests() {
  await app.replicant?.invoke.cheat_CleanEarnPageQuests();
}

async function disconnectTONWalletOnly() {
  await app.tonConnectUI?.disconnect();
}

async function resetEarning(earningKey: string) {
  await app.replicant?.invoke.cheat_ResetEarning({
    earningKey: earningKey as any,
  });
}

async function clearWallet() {
  await app.replicant?.invoke.cheat_ClearWallet();
}

async function resetInteraction() {
  await app.replicant?.invoke.cheat_firstInteraction();
  await waitFor(5000);
  window.location.reload();
}

async function refillTMGTickets() {
  await app.replicant?.invoke.cheat_refillTGMTickets();
}

async function sendMemeTokenGiftMessage(strict?: boolean) {
  if (!app.replicant) {
    return;
  }

  await app.replicant.invoke.cheat_sendMemeTokenGiftMessage({ strict });
}

export const cheats: UISchema = {
  Taps: {
    '+1t': async () => {
      addPoints(1_000_000_000_000);
    },
    '+1b': async () => {
      addPoints(1_000_000_000);
    },
    '+100m': async () => {
      addPoints(100_000_000);
    },
    '+10m': async () => {
      addPoints(10_000_000);
    },
    '+1m': async () => {
      addPoints(1_000_000);
    },
    '+100k': async () => {
      addPoints(100_000);
    },
    '+10k': async () => {
      addPoints(10_000);
    },
    '+1k': async () => {
      addPoints(1_000);
    },
    '+100': async () => {
      addPoints(100);
    },
    '-100': async () => {
      addPoints(-100);
    },
    '-1k': async () => {
      addPoints(-1_000);
    },
    '-10k': async () => {
      addPoints(-10_000);
    },
    '-100k': async () => {
      addPoints(-100_000);
    },
    '-1m': async () => {
      addPoints(-1_000_000);
    },
    '-10m': async () => {
      addPoints(-10_000_000);
    },
    '-100m': async () => {
      addPoints(-100_000_000);
    },
    '-1b': async () => {
      addPoints(-1_000_000_000);
    },
    '-1t': async () => {
      addPoints(-1_000_000_000_000);
    },
    before_league1: async () => {
      setTaps(19999);
    },
    before_league2: async () => {
      setTaps(199998);
    },
    '14500': async () => {
      setBalance(14500, 5);
    },
  },
  Bot: {
    'Send reengagement message': async () => {
      await app.replicant?.invoke.cheat_SendReengagementMessage();
    },
    'Send reengagement broadcast message': async () => {
      await app.replicant?.invoke.cheat_SendReengagementBroadcast();
    },
    'Send energy recharged message': async () => {
      await app.replicant?.invoke.cheat_SendEnergyRechargedMessage();
    },
    'Send friend joined message': async () => {
      await app.replicant?.invoke.cheat_SendLevelUpMessage();
    },
    'Send incremental league reward': async () => {
      await app.replicant?.invoke.cheat_SendFriendIncrementalLeague();
    },
    'Send Trading Card Price Up': async () => {
      await app.replicant?.invoke.cheat_SendOffchainTokenPriceUpMessage();
    },
    'Send Trading Card Price Down': async () => {
      await app.replicant?.invoke.cheat_SendOffchainTokenPriceDownMessage();
    },
    'Send AB0075 Reengament Message': async () => {
      await app.replicant?.invoke.cheat_SendReengamentMessage();
    },
    'Send Meme Token Gift message': async () => {
      await sendMemeTokenGiftMessage();
    },
    'Send Meme Token Gift message STRICT': async () => {
      await sendMemeTokenGiftMessage(true);
    },
  },
  Friends: {
    '+1': async () => {
      addFakeFriend();
    },
    '+10': async () => {
      addFakeFriend(10);
    },
    '+100': async () => {
      addFakeFriend(100);
    },
    reset: async () => {
      addFakeFriend(0, true);
    },
  },
  Wallet: {
    'Disconnect TON Wallet': async () => {
      await disconnectTONWalletOnly();
    },
    'Clear wallet': async () => {
      await clearWallet();
    },
  },
  Earnings: {
    'Clean all quests': async () => {
      cleanEarnPageQuests();
    },
    'Reset Wallet Connect': async () => {
      resetEarning('walletConnect');
    },
  },
  Tutorial: {
    resetInteraction: async () => {
      resetInteraction();
    },
  },
  Promo: {
    'Copy edcon link A': async () => {
      const link = generatePromoLink('pu_specials_edcon_2024', 'a');
      console.log('PROMOLINK A', link);
    },
    'Copy edcon link B': async () => {
      const link = generatePromoLink('pu_specials_edcon_2024', 'b');
      console.log('PROMOLINK B', link);
    },
  },
  TradingTokens: {
    '+10': async () => {
      console.log('Adding 1 token');
      await app.automation.generateTestData(10);
    },
    '+100': async () => {
      console.log('Adding 1 token');
      await app.automation.generateTestData(100);
    },
    '+1000': async () => {
      console.log('Adding 1 token');
      await app.automation.generateTestData(1000);
    },
    refillTickets: async () => {
      await refillTMGTickets();
    },
  },
  AddLabels: {
    'S3 Teaser': async () => {
      app.invoke.addLabels({
        labels: [ModalLabels.SHOW_TIKTOK_TEASER_S3],
      });
    },
  },
};

export default cheats;
