import {
  action,
  asyncAction,
  ReplicantAsyncActionAPI,
} from '@play-co/replicant';
import { createActions } from '../../createActions';
import { TradingTokenInput } from './types';
import {
  confirmTransaction,
  getActualTime,
  getBuyEstimate,
  getCoinAmountForTokenSell,
  getCreateEstimate,
  getCurvePrice,
  getFtueShareGateReward,
  getMyOffchainTokenIds,
  getOffchainToken,
  getSellEstimate,
  getTTGCanClaim,
  getTTGFarmingPoints,
  getTxWithinEstimate,
  hasReachedMemeCreationLimit,
  hasReachedMemeHoldingLimit,
} from './offchainTrading.getters';
import { ErrorCode, errorResponse, successResponse } from '../../response';
import {
  giveFreeToken,
  incrementBalance,
  incrementScore,
  spendCoins,
} from '../game/game.modifiers';
import Big from 'big.js';
import {
  memeGiftRuleset,
  season2StartTime,
  shortestPortofolioUpdateInterval,
  tmgRuleset,
  txConfig,
} from './offchainTrading.ruleset';
import { updateStatus } from './offchainTrading.utils';
import {
  savePortfolioPricePoint,
  tmgStartTokenFarming,
  tmgSyncTapsOnSessionComplete,
  startGameSession,
  convertScore,
  updateTokenHolding,
} from './offchainTrading.modifiers';
import { PeriodicUpdate } from './offchainTrading.messages';
import { MutableState } from '../../schema';
import { fetchPlayerState } from '../game/game.getters';
import { retry } from '../../lib/async';
import { stage } from '../game/game.config';
import { OffchainTokenStatus } from './offchainTrading.schema';
import { DAY_IN_MS } from '../../utils/time';
import {
  getOwnMemeGiftId,
  getUserMemeGiftId,
  hasReceivedUserMemeGift,
} from '../powerups/getters';

export interface CreateCardPayload {
  offchainToken: TradingTokenInput & { id: string };
  currencyAmount: string;
  productId: string;
  isLocal: boolean;
  useCredit: boolean;
}

export async function createOffchainToken(
  state: MutableState,
  payload: CreateCardPayload,
  api: ReplicantAsyncActionAPI<any>,
) {
  if (hasReachedMemeCreationLimit(state)) {
    return errorResponse('Creation limit reached', {
      code: ErrorCode.CREATION_LIMIT_REACHED,
    });
  }

  let currencyAmountBig = Big(payload.currencyAmount);
  if (currencyAmountBig.gt(state.balance)) {
    return errorResponse('Not enough funds', {
      code: ErrorCode.NOT_ENOUGH_FUNDS,
    });
  }
  if (payload.useCredit && state.tokenCreationCredits <= 0) {
    return errorResponse('Not enough stars');
  }

  try {
    // Create shared state for offchainToken.
    const offchainTokenId = api.sharedStates.offchainTrading.create(
      `offchainTrading-${payload.offchainToken.id}`,
    );
    // We must remove the id from input because it's not expected by message; From now own we use `offchainTokenId`
    // @ts-ignore
    delete payload.offchainToken.id;

    const timestamp = getActualTime(api);

    const offchainTokenDetails = {
      creatorId: state.id,
      creatorName: state.profile.name,
      creatorImage: state.profile.photo,
      // @todo: update availableAt property only after moderation
      availableAt: timestamp,
      ...payload.offchainToken,
    };

    // Populate the new offchainToken data
    api.sharedStates.offchainTrading.postMessage.createOffchainToken2(
      offchainTokenId,
      {
        details: offchainTokenDetails,
        timestamp,
        currencyAmount: payload.currencyAmount.toString(),
        isDev: payload.isLocal,
      },
    );

    // @note: the player can create a token without spending any currency
    // Add offchainToken(s) to user state

    state.trading.lastTokenCreatedTimestamp = timestamp;

    spendCoins(state, currencyAmountBig.toNumber(), api.date.now());

    const tokenAmount = currencyAmountBig.gt(0)
      ? getCreateEstimate(currencyAmountBig)
      : Big(0);

    const currencyInvested = currencyAmountBig
      .mul(1 - txConfig.fee)
      // .div(txConfig.buyModifier)
      .round()
      .toString();

    updateTokenHolding(state, offchainTokenId, {
      currencyInvested,
      tokenAmount: tokenAmount.toString(),
      pointsAccumulated: tokenAmount.toString(),
      lastNotifPrice: getCurvePrice(tokenAmount).toString(),
      productId: payload.productId,
    });

    // This consumes the telegram star purchase
    if (!payload.isLocal && !payload.useCredit) {
      api.purchases.consumePurchase({ productId: payload.productId });
    }

    if (payload.useCredit) {
      state.tokenCreationCredits--;
    }

    return successResponse({ offchainTokenId });
  } catch (e: any) {
    return errorResponse(e.message);
  }
}

export const offchainTradingActions = createActions({
  asyncTakePortfolioSnapshots: asyncAction(async (state, _: void, api) => {
    if (getMyOffchainTokenIds(state).length === 0) {
      return;
    }

    // generate a portfolio price point
    await savePortfolioPricePoint(state, api);

    // schedule another one for later
    // overrides previous schedules
    api.scheduledActions.schedule.savePortfolioPricePoint({
      args: { delay: shortestPortofolioUpdateInterval },
      notificationId: 'savePortfolioPricePoint',
      delayInMS: shortestPortofolioUpdateInterval,
    });
  }),
  asyncCreateOffchainToken: asyncAction(
    async (
      state,
      {
        offchainToken,
        currencyAmount,
        productId,
        isLocal,
        useCredit,
      }: {
        offchainToken: TradingTokenInput & { id: string };
        currencyAmount: string;
        productId: string;
        isLocal: boolean;
        useCredit: boolean;
      },
      api,
    ) => {
      return createOffchainToken(
        state,
        {
          offchainToken: offchainToken,
          currencyAmount: currencyAmount,
          productId: productId,
          isLocal: isLocal,
          useCredit: useCredit,
        },
        api,
      );
    },
  ),
  asyncCreateOffchainTokenForTestUser: asyncAction(
    async (
      state,
      payload: { testUserId: string; createCardPayload: CreateCardPayload },
      api,
    ) => {
      const testUserState = await api.asyncGetters.getUserState({
        userId: `${state.id}_${payload.testUserId}`,
      });

      incrementScore(testUserState, 10000);

      return await createOffchainToken(
        testUserState,
        payload.createCardPayload,
        api,
      );
    },
  ),
  asyncEditOffchainToken: asyncAction(
    async (
      state,
      {
        tokenId,
        telegramChannelLink,
        telegramChatLink,
        twitterLink,
      }: {
        tokenId: string;
        telegramChannelLink?: string;
        telegramChatLink?: string;
        twitterLink?: string;
      },
      api,
    ) => {
      try {
        await api.sharedStates.offchainTrading.postMessage.editOffchainToken(
          tokenId,
          {
            telegramChannelLink,
            telegramChatLink,
            twitterLink,
          },
        );

        return successResponse({});
      } catch (e: any) {
        return errorResponse(e.message);
      }
    },
  ),
  buyOffchainToken: asyncAction(
    async (
      state,
      payload: {
        offchainTokenId: string;
        tokenAmountEstimate: string;
        currencyAmount: string;
        testUserId?: string;
      },
      api,
    ) => {
      let userState = state;
      if (payload.testUserId) {
        const testUserState = await api.asyncGetters.getUserState({
          userId: `${userState.id}_${payload.testUserId}`,
        });
        if (testUserState) {
          userState = testUserState;
        }
      }

      const { offchainTokenId, tokenAmountEstimate, currencyAmount } = payload;

      if (hasReachedMemeHoldingLimit(state, offchainTokenId)) {
        return errorResponse('Holding limit reached', {
          code: ErrorCode.HOLDING_LIMIT_REACHED,
        });
      }

      const tokenAmountEstimateBig = Big(tokenAmountEstimate);
      const currencyAmountBig = Big(currencyAmount);
      const offchainTokenState = await api.sharedStates.offchainTrading.fetch(
        offchainTokenId,
      );

      const offchainToken = offchainTokenState?.global;
      if (!offchainToken) {
        throw new Error(
          `Attempting to buy offchainToken that doesn't exist '${offchainTokenId}'.`,
        );
      }

      if (offchainToken.createdAt < season2StartTime) {
        return errorResponse(
          'Token predates meme feature official start time',
          {
            code: ErrorCode.INVALID_TOKEN,
            data: { createdAt: offchainToken.createdAt },
          },
        );
      }

      if (
        !(
          offchainToken.status.includes(OffchainTokenStatus.Moderated) ||
          offchainToken.status.includes(OffchainTokenStatus.ModeratedOS)
        )
      ) {
        return errorResponse(
          `Token with status "${offchainToken.status}" cannot be transacted`,
          {
            code: ErrorCode.NON_TRANSACTABLE_TOKEN,
          },
        );
      }

      const cannotAfford = currencyAmountBig.gt(userState.balance);

      if (cannotAfford) {
        return errorResponse('Not enough funds', {
          code: ErrorCode.NOT_ENOUGH_FUNDS,
          // data: { diff: offchainTokenBuyPrice - userState.balance },
        });
      }

      const driftPct = userState.trading.maxSlippage;
      const tokenAmount = getBuyEstimate(offchainToken, currencyAmountBig);

      const priceWithinRange = getTxWithinEstimate(
        tokenAmount,
        tokenAmountEstimateBig,
        driftPct,
      );

      if (!priceWithinRange) {
        return errorResponse('Price has changed', {
          code: ErrorCode.OFFCHAIN_TOKEN_PRICE_DRIFT,
          data: { newEstimate: tokenAmount },
        });
      }

      const timestamp = getActualTime(api);
      const myToken = userState.trading.offchainTokens[offchainTokenId];
      const isNewHolder = !myToken || Big(myToken.tokenAmount).eq(0);
      const tokenAmountHeldBeforeTx = myToken?.tokenAmount;

      api.sharedStates.offchainTrading.postMessage.attemptBuyOffchainToken2(
        offchainTokenId,
        {
          checkCanBuy: true,
          timestamp,
          expectedTxIdx: offchainToken.txs.length,
          buyerId: userState.id,
          buyerName: userState.profile.name,
          buyerImage: userState.profile.photo,
          currencyAmount: currencyAmount.toString(),
          tokenAmountEstimate: tokenAmountEstimate.toString(),
          tokenAmountHeldBeforeTx: tokenAmountHeldBeforeTx ?? '0',
          driftPct,
          isNewHolder,
        },
      );

      const txConfirmation = await confirmTransaction(
        api,
        userState.id,
        offchainTokenId,
        timestamp,
      );

      if (!txConfirmation) {
        return errorResponse('Failed to buy. Please try again', {
          code: ErrorCode.OFFCHAIN_TOKEN_PURCHASE_FAILED,
        });
      }

      const transaction = txConfirmation.transaction;

      // remove currencyAmount from user
      spendCoins(userState, currencyAmountBig.toNumber(), api.date.now());

      const currencyInvested = currencyAmountBig
        // .div(txConfig.buyModifier)
        .mul(1 - txConfig.fee)
        .round();

      const supply = offchainToken.supply;
      const txTokenAmount = transaction.tokenAmount;
      const lastNotifPrice = getCurvePrice(Big(supply).add(txTokenAmount));

      // update offchainToken on user state
      updateTokenHolding(
        state,
        offchainTokenId,
        {
          tokenAmount: txTokenAmount,
          pointsAccumulated: txTokenAmount,
          currencyInvested: currencyInvested.toString(),
          lastNotifPrice: lastNotifPrice.toString(),
        },
        true,
      );

      return successResponse(
        getOffchainToken(txConfirmation.offchainToken, offchainTokenId),
      );
    },
  ),
  sellOffchainToken: asyncAction(
    async (
      state,
      payload: {
        offchainTokenId: string;
        currencyAmountEstimate: string;
        tokenAmount: string;
        testUserId?: string;
      },
      api,
    ) => {
      let userState = state;
      if (payload.testUserId) {
        const testUserState = await api.asyncGetters.getUserState({
          userId: `${state.id}_${payload.testUserId}`,
        });
        if (testUserState) {
          userState = testUserState;
        }
      }

      const { offchainTokenId, currencyAmountEstimate, tokenAmount } = payload;
      const tokenAmountBig = Big(tokenAmount);
      const currencyAmountEstimateBig = Big(currencyAmountEstimate);
      const offchainTokenState = await api.sharedStates.offchainTrading.fetch(
        offchainTokenId,
      );
      const offchainToken = offchainTokenState?.global;
      if (!offchainToken) {
        throw new Error(
          `Attempting to buy offchainToken that doesn't exist '${offchainTokenId}'.`,
        );
      }

      if (offchainToken.createdAt < season2StartTime) {
        return errorResponse(
          'Token predates meme feature official start time',
          {
            code: ErrorCode.INVALID_TOKEN,
            data: { createdAt: offchainToken.createdAt },
          },
        );
      }

      if (offchainToken.status.includes('deleted')) {
        return errorResponse(
          `Token with status "${offchainToken.status}" cannot be transacted`,
          {
            code: ErrorCode.NON_TRANSACTABLE_TOKEN,
          },
        );
      }

      const ownsEnoughOffchainTokens = Big(
        state.trading.offchainTokens[offchainTokenId]?.tokenAmount,
      ).gte(tokenAmount);

      if (!ownsEnoughOffchainTokens) {
        return errorResponse('Do not have enough offchainTokens to sell');
      }

      const currentSupply = Big(offchainToken.supply);
      const supplyAfterSell = currentSupply.minus(tokenAmount);

      if (supplyAfterSell.lt(0)) {
        return errorResponse('Cannot sell last copy of a offchainToken', {
          code: ErrorCode.CUSTOM_SENTRY_TRACK,
          data: {
            message: 'FE should not have allowed supplyAfterSell to go below 0',
            offchainTokenId,
            userId: state.id,
            supplyAfterSell,
          },
        });
      }

      // const offchainTokenSellPrice = getOffchainTokensPrice(amount, 'sell', currentOffchainTokenSupply - 1);
      // // Should never happen
      // if (!offchainTokenSellPrice || offchainTokenSellPrice === -1) {
      //   return errorResponse('Unexpected error occured.', {
      //     code: ErrorCode.CUSTOM_SENTRY_TRACK,
      //     data: {
      //       message: 'Could not find sell price for given sell token amount',
      //       offchainTokenId,
      //       userId: state.id,
      //       supplyAfterSell,
      //     },
      //   });
      // }
      const driftPct = state.trading.maxSlippage;
      const currencyAmount = getSellEstimate(offchainToken, tokenAmountBig);

      const priceWithinRange = getTxWithinEstimate(
        currencyAmount,
        currencyAmountEstimateBig,
        driftPct,
      );

      if (!priceWithinRange) {
        return errorResponse('Price has changed', {
          code: ErrorCode.OFFCHAIN_TOKEN_PRICE_DRIFT,
          data: { newEstimate: currencyAmount },
        });
      }

      const timestamp = getActualTime(api);

      const tokenAmountHeldBeforeTx =
        state.trading.offchainTokens[offchainTokenId].tokenAmount;
      api.sharedStates.offchainTrading.postMessage.attemptSellOffchainToken2(
        offchainTokenId,
        {
          checkCanSell: true,
          timestamp,
          expectedTxIdx: offchainToken.txs.length,
          sellerId: state.id,
          sellerName: state.profile.name,
          sellerImage: state.profile.photo,
          currencyAmountEstimate: currencyAmountEstimate.toString(),
          tokenAmountHeldBeforeTx: tokenAmountHeldBeforeTx ?? '0',
          tokenAmount: tokenAmount.toString(),
          driftPct,
          sellingAllTokens: Big(tokenAmountHeldBeforeTx).eq(tokenAmount),
        },
      );

      const txConfirmation = await confirmTransaction(
        api,
        state.id,
        offchainTokenId,
        timestamp,
      );
      if (!txConfirmation) {
        return errorResponse('Failed to sell. Please try again', {
          code: ErrorCode.OFFCHAIN_TOKEN_PURCHASE_FAILED,
        });
      }

      const transaction = txConfirmation.transaction;
      const txTokenAmount = transaction.tokenAmount;

      const userReward = getCoinAmountForTokenSell(
        currentSupply,
        Big(txTokenAmount),
      );

      // const userReward = getFinalSellPrice(lastTx.currencyAmount);
      // give currencyAmount to user

      const currencyRecovered = Math.ceil(userReward.toNumber());
      incrementBalance(state, currencyRecovered);
      state.trading.offchain.currencyRecovered = Big(
        state.trading.offchain.currencyRecovered,
      )
        .plus(currencyRecovered)
        .round() //just in case
        .toString();

      const tokenHolding = state.trading.offchainTokens[offchainTokenId];

      // remove offchainTokens to user state
      const currentTokenAmount = Big(tokenHolding.tokenAmount);

      const newTokenAmount = currentTokenAmount.minus(tokenAmount);
      tokenHolding.tokenAmount = newTokenAmount.toString();

      // If we have no more of the tokens remove from state
      if (newTokenAmount.lte(0)) {
        delete state.trading.offchainTokens[offchainTokenId];
      } else {
        // --- added for roi
        const ratioTokensRemaining = newTokenAmount.div(currentTokenAmount);
        const newCurencyInvested = Big(tokenHolding.currencyInvested)
          .mul(ratioTokensRemaining)
          .round();
        tokenHolding.currencyInvested = newCurencyInvested.toString();

        tokenHolding.lastNotifPrice = getCurvePrice(
          Big(offchainToken.supply).minus(txTokenAmount),
        ).toString();
      }

      return successResponse({
        token: getOffchainToken(txConfirmation.offchainToken, offchainTokenId),
        amount_divested: userReward,
      });
    },
  ),
  asyncAddImage: asyncAction(
    async (
      _state,
      { offchainTokenId, image }: { offchainTokenId: string; image: string },
      api,
    ) => {
      let offchainTokenState = await api.sharedStates.offchainTrading.fetch(
        offchainTokenId,
      );
      if (!offchainTokenState) {
        return errorResponse('OffchainToken not found');
      }
      api.sharedStates.offchainTrading.postMessage.addImageUrl(
        offchainTokenId,
        {
          image,
        },
      );
      return successResponse({
        offchainTokenId,
        image,
      });
    },
  ),
  asyncUpdateStatus: asyncAction(
    async (
      _state,
      {
        offchainTokenIds,
        tokenStatus,
      }: { offchainTokenIds: string[]; tokenStatus?: string },
      api,
    ): Promise<
      {
        offchainTokenId: string;
        status: string;
      }[]
    > => {
      return await updateStatus(api, offchainTokenIds, tokenStatus);
    },
  ),
  removeDeletedOffchainTokens: asyncAction(
    async (
      state,
      { offchainTokens }: { offchainTokens: { id: string }[] },
      _api,
    ) => {
      for (const { id } of offchainTokens) {
        const offchainToken = state.trading.offchainTokens[id];
        if (offchainToken) {
          incrementBalance(
            state,
            Math.ceil(Big(offchainToken.currencyInvested).toNumber()),
          );
          delete state.trading.offchainTokens[id];
        }
      }
    },
  ),
  removeCompletelySoldOffchainTokens: asyncAction(
    async (state, _payload, _api) => {
      for (const id in state.trading.offchainTokens) {
        const offchainToken = state.trading.offchainTokens[id];
        if (offchainToken) {
          const tokenAmount = Number(
            Big(offchainToken.tokenAmount).toNumber().toFixed(8),
          );
          if (tokenAmount === 0) {
            delete state.trading.offchainTokens[id];
          }
        }
      }
    },
  ),
  flushMessages: asyncAction(async (_state, _payload, api) => {
    await api.flushMessages();
  }),
  periodicUpdate: action(
    (state, payload: Record<string, PeriodicUpdate>, api) => {
      const tokenGameStates = state.trading.miniGames.state;
      Object.keys(tokenGameStates).forEach((tokenId) => {
        const stateUpdate = payload[tokenId];

        if (!stateUpdate) {
          return;
        }

        api.sharedStates.offchainTrading.postMessage.periodicUpdate(
          tokenId,
          stateUpdate,
        );
      });
    },
  ),

  /**
   * returns `true` if the token has started farming or `false` if it didnt
   */
  tmgStartFarmingToken: action((state, payload: { tokenId: string }, api) => {
    return tmgStartTokenFarming(state, payload, api.date.now());
  }),
  tmgClaimFarmingReward: asyncAction(
    async (state, { tokenId }: { tokenId: string }, api) => {
      const now = api.date.now();

      if (!getTTGCanClaim(state, tokenId, now)) {
        return '-1';
      }

      // give reward
      const reward = getTTGFarmingPoints(state, tokenId, now);

      const tokenUpdate = await convertScore(state, api, {
        tokenId,
        score: reward,
      });

      if (!tokenUpdate) {
        return '0';
      }

      updateTokenHolding(state, tokenId, tokenUpdate, true);

      state.trading.miniGames.state[tokenId].miningStart = undefined;

      const points = JSON.parse(
        JSON.stringify(tokenUpdate.tokenAmount),
      ) as string;

      return points;
    },
  ),
  tmgTokenTap: action((state, _, api) => {
    state.trading.miniGames.tapping.sessionTaps +=
      tmgRuleset.tappingScorePerTap;
  }),
  tmgStartSession: action((state, { tokenId }: { tokenId: string }, api) => {
    const sessionStarted = startGameSession(state, api, tokenId);
    const hasConsumedTicket = JSON.parse(JSON.stringify(sessionStarted));
    return hasConsumedTicket;
  }),
  tmgHandleTapSessionEnd: asyncAction(
    async (state, { tokenId }: { tokenId: string }, api) => {
      const taps = tmgSyncTapsOnSessionComplete(state, tokenId, api.date.now());

      if (stage !== 'prod') {
        api.sendAnalyticsEvents([
          {
            eventType: 'DebugTapSessionEnd1',
            eventProperties: {
              taps,
            },
          },
        ]);
      }

      try {
        const tokenUpdate = await retry(
          () =>
            convertScore(state, api, {
              tokenId,
              score: taps,
            }),
          {
            attempts: 5,
          },
        );

        if (stage !== 'prod') {
          api.sendAnalyticsEvents([
            {
              eventType: 'DebugTapSessionEnd2',
              eventProperties: {
                taps,
                ...tokenUpdate,
              },
            },
          ]);
        }

        updateTokenHolding(state, tokenId, tokenUpdate);

        state.trading.miniGames.tapping.sessionKickbackTaps += taps;
        state.trading.miniGames.tapping.sessionTokenId = tokenId;

        const points = JSON.parse(
          JSON.stringify(tokenUpdate.tokenAmount),
        ) as string;

        return points;
      } catch (error) {
        // @todo: shouldn't we return the ticket in that case?
        return '0';
      }
    },
  ),
  tmgTriggerKickbackReward: asyncAction(async (state, _, api) => {
    const tokenId = state.trading.miniGames.tapping.sessionTokenId;
    if (!tokenId) {
      return;
    }

    const taps = state.trading.miniGames.tapping.sessionKickbackTaps;

    state.trading.miniGames.tapping.sessionKickbackTaps = 0;
    delete state.trading.miniGames.tapping.sessionTokenId;

    const myReferrerId = state.referrer_id;
    const myReferrerTokenId = state.trading.referrerTokenId;
    if (!myReferrerId || myReferrerTokenId !== tokenId) {
      return;
    }

    // 10% bonus to direct referrer
    const R1Kickback = Math.round(taps * 0.1);
    if (R1Kickback <= 0) {
      return;
    }

    const myReferrerState = await fetchPlayerState(api, myReferrerId);
    if (!myReferrerState) {
      return;
    }

    let scoreConversionR1;
    try {
      scoreConversionR1 = await retry(
        () =>
          convertScore(myReferrerState, api, {
            tokenId,
            score: R1Kickback,
          }),
        {
          attempts: 5,
        },
      );
    } catch (error) {
      console.error(
        `Failed to convert kickback score from ${state.id} to direct referrer ${myReferrerId} for meme ${tokenId}`,
      );
    }

    // @note: message posting should be done outside of a try catch
    if (scoreConversionR1) {
      api.postMessage.creditReferrerKickBack(myReferrerId, {
        tokenUpdate: scoreConversionR1,
        points: Number(scoreConversionR1.tokenAmount),
        tokenId,
      });
    }

    // 2.5% bonus to referrer of our referrer
    const R2Kickback = Math.round(taps * 0.025);

    const parentReferrerId = myReferrerState.referrer_id;
    const parentReferrerTokenId = myReferrerState.trading.referrerTokenId;
    if (
      R1Kickback <= 0 ||
      !parentReferrerId ||
      parentReferrerTokenId !== tokenId
    ) {
      return;
    }

    const parentReferrerState = await fetchPlayerState(api, parentReferrerId);
    if (!parentReferrerState) {
      return;
    }

    let scoreConversionR2;
    try {
      scoreConversionR2 = await retry(
        () =>
          convertScore(parentReferrerState, api, {
            tokenId,
            score: R2Kickback,
          }),
        {
          attempts: 5,
        },
      );
    } catch (error) {
      console.error(
        `Failed to convert kickback score from ${state.id} to parent referrer ${parentReferrerId} for meme ${tokenId}`,
      );
    }

    // @note: message posting should be done outside of a try catch
    if (scoreConversionR2) {
      api.postMessage.creditReferrerKickBack(parentReferrerId, {
        tokenUpdate: scoreConversionR2,
        points: Number(scoreConversionR2.tokenAmount),
        tokenId,
      });
    }
  }),
  setHasSeenTokenDisplayChange: action((state, _, api) => {
    state.trading.hasSeenTokenDisplayChange = true;
  }),
  generateUserMemeGift: action((state, { memeId }: { memeId: string }, api) => {
    const shareTime = api.date.now();
    const giftId = getOwnMemeGiftId(memeId, shareTime);
    const reward = memeGiftRuleset.giftReward;

    // @todo: add restrictiong to send gifts?
    state.trading.userMemeGiftsSent[giftId] = {
      reward,
      shareTime,
      claimed: false,
    };

    return shareTime;
  }),
  grantFtueShareReward: asyncAction(
    async (state, { memeId }: { memeId: string }, api) => {
      if (state.trading.ftueShareGatePassed) {
        return;
      }
      state.trading.ftueShareGatePassed = true;

      const reward = getFtueShareGateReward(state);
      await giveFreeToken(state, api, memeId, reward);
    },
  ),
  claimUserMemeGift: asyncAction(
    async (
      state,
      {
        senderId,
        tokenId,
        shareTime,
      }: { tokenId: string; senderId: string; shareTime: number },
      api,
    ) => {
      const now = api.date.now();
      if (shareTime + DAY_IN_MS < now) {
        // gift is expired
        return {
          expired: true,
        };
      }

      // gift is not expired
      const giftId = getUserMemeGiftId(senderId, tokenId, shareTime);
      if (hasReceivedUserMemeGift(state, senderId, tokenId, shareTime)) {
        return {
          alreadyClaimed: true,
        };
      }

      // gift was not received by this player

      const senderState = await fetchPlayerState(api, senderId);
      if (!senderState) {
        // sender does not exist, hack attempt?
        return {
          cannotFindUserState: true,
        };
      }

      console.log('claimUserMemeGift', {
        senderState,
        id: getOwnMemeGiftId(tokenId, shareTime),
      });

      const gift =
        senderState.trading.userMemeGiftsSent[
          getOwnMemeGiftId(tokenId, shareTime)
        ];
      if (!gift) {
        // gift does not exists, hack attempt?
        return {
          noGift: true,
        };
      }

      // user is claiming a reward (either gift or consolation)
      state.trading.userMemeGiftsClaimed[giftId] = gift.shareTime;

      if (gift.claimed) {
        incrementBalance(state, memeGiftRuleset.consolationCoinReward);
        // already claimed
        return {
          consolation: memeGiftRuleset.consolationCoinReward,
        };
      }

      const scoreEquivalent = gift.reward;

      const receiverGiftGrant = await giveFreeToken(
        state,
        api,
        tokenId,
        scoreEquivalent,
      );

      // Update senders state to gift claimed
      api.postMessage.setGiftAsClaimed(senderId, {
        tokenId,
        shareTime,
      });

      const points = JSON.parse(
        JSON.stringify(receiverGiftGrant?.tokenAmount),
      ) as string;

      return {
        points,
      };
    },
  ),
});
