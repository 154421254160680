import './TradingPage.scss';
import './TradingTabs/TradingTabs.scss';
import { useTranslation } from 'react-i18next';
import { Page } from '../Page';
import { useEffect, useState } from 'react';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import { UIEvents } from '../../../data/Controllers/UIController/UITypes';
import { TradingHeader } from './TradingHeader/TradingHeader';
import { TradingSearchBar } from './TradingSearchBar/TradingSearchBar';
import { TradingTokenList } from './TradingTabs/TradingTokenList';
import { Tabs, TabStyle } from '../../shared/Tabs/Tabs';
import { RoiSummary } from './RoiSummary/RoiSummary';
import { TradingSortingHeader } from './TradingSortingHeader/TradingSortingHeader';
import { TradingGemzCoinItem } from './TradingGemzCoinItem/TradingGemzCoinItem';
import { MemesEvents } from '../../../data/Controllers/Memes/MemesController';
import { MemeFilters } from '../../../data/Controllers/Memes/types';
import {
  hasSeenTokenDisplayChange,
  hasTradedBefore,
} from '../../../replicant/features/offchainTrading/offchainTrading.getters';
import { useManyAppUpdates } from '../../../data/hooks';

export type TradingCategories = 'Hot' | 'Top' | 'New' | 'Created' | 'Owned';

export const TradingPage = () => {
  const { t } = useTranslation();
  const { visible } = app.views.TradingPage;

  useManyAppUpdates({
    id: 'TradingPage',
    events: [
      { listener: app.views.TradingPage.attachEventListener() },
      {
        listener: app.ui.attachEventListener(UIEvents.OnBalanceUpdate),
        dep: visible,
      },
      {
        listener: app.memes.attachEventListener(MemesEvents.OnListingUpdate),
        dep: visible,
      },
      {
        listener: app.ui.attachEventListener(UIEvents.TradingUpdate),
        dep: visible,
      },
    ],
  });

  // ---------------------------
  // initialize

  const [currentTab, setCurrentTab] = useState(
    app.memes.currentFilter as TradingCategories,
  );

  const [searchTerm, setSearchTerm] = useState('');

  const onTabClick = (tab: TradingCategories, _prev: TradingCategories) => {
    app.memes.setCurrent({ filter: tab as MemeFilters });
    setCurrentTab(tab);
    app.track('memeoffchainToken_tab_view', {
      tab_name: tab,
    });
    app.tutorial.step?.onAction?.();

    setSearchTerm('');
  };

  // On visibility change
  useEffect(() => {
    if (visible) {
      app.track('memeoffchainToken_page_view', {
        source:
          app.nav.prevRoute === 'MinePage'
            ? 'mine_page'
            : app.nav.prevRoute === 'Clicker'
            ? 'nav_menu'
            : 'other',
      });

      if (hasTradedBefore(app.state) && !hasSeenTokenDisplayChange(app.state)) {
        app.ui.drawer.show({
          id: 'drawerTradingWarning',
          props: {
            tradingWarning: {
              warningTitle: t('trading_token_quantity_change_title'),
              warningMessage: t('trading_token_quantity_change_message'),
              warningCta: t('trading_token_quantity_change_cta'),
            },
          },
        });

        app.track('Bond_changes_modal');
      }

      app.invoke.setHasSeenTokenDisplayChange();
    }
  }, [visible]);

  // todo: we need to implement sortBy functionality both in profile and roi summary

  // ---------------------------
  // render

  const isEndOfList = app.memes.market.isEndOfList();
  const placeholderCount = isEndOfList ? 0 : 10;

  return (
    <Page id="modal-trading" visible={visible}>
      <TradingHeader />
      <TradingSearchBar
        initialSearchTerm={searchTerm}
        onInputChange={(term: string) => setSearchTerm(term)}
      />
      <div className="trading-tabs-container">
        <Tabs<TradingCategories>
          tabStyle={TabStyle.Underline}
          initial={app.memes.currentFilter as TradingCategories}
          onTabClick={onTabClick}
          autoSelected={app.memes.currentFilter as TradingCategories}
          // uiState={app.ui.state.mine.btnTab}
          tabs={[
            {
              id: 'Hot',
              name: t('trading_tab_hot'),
              badge: { visible: false, amount: 3, checked: false },
              component: (
                <TradingTokenList
                  items={app.memes.market.listing}
                  track="hot"
                  placeholderCount={placeholderCount}
                />
              ),
            },
            {
              id: 'Top',
              name: t('trading_tab_top'),
              badge: { visible: false, amount: 2, checked: false },
              component: (
                <TradingTokenList
                  items={app.memes.market.listing}
                  track="top"
                  placeholderCount={placeholderCount}
                />
              ),
            },
            {
              id: 'New',
              name: t('trading_tab_new'),
              badge: { visible: false, amount: 3, checked: false },
              component: (
                <TradingTokenList
                  items={app.memes.market.listing}
                  track="new"
                  placeholderCount={placeholderCount}
                />
              ),
            },
            {
              id: 'Owned',
              name: t('trading_tab_my_cards'),
              badge: { visible: false, amount: 1, checked: false },
              component: (
                <>
                  {/* roi - summary */}
                  <RoiSummary />

                  <TradingSortingHeader
                    totalLabel={''}
                    totalValue={app.memes.trading.getOffchainTokenHoldCount()}
                    list={app.memes.market.listing}
                  />

                  <TradingGemzCoinItem balance={app.state.balance} />

                  {/* list */}
                  <TradingTokenList
                    items={app.memes.myMemes.listing}
                    track="myCards"
                    placeholderCount={0}
                  />
                </>
              ),
            },
          ]}
        />
      </div>
    </Page>
  );
};
