import { MutableState, State } from '../../schema';
import {
  confirmTransaction,
  getActualTime,
  getCurvePrice,
  getPortfolioValue,
  getTmgTicketTimestamp,
  getTMGTappingMaxTickets,
  getTTGCanClaim,
  getTTGFarmingPoints,
  getTTGFarmingSpotsAvailable,
  getTTGIsFarming,
} from './offchainTrading.getters';
import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
  ReplicantSyncActionAPI,
} from '@play-co/replicant';
import { ReplicantClient, ReplicantServer } from '../../config';
import {
  FixedSliceTimeWindows,
  fixedSliceTimeWindows,
  maxPortfolioAllTimeSliceCount,
  tmgRuleset,
  portfolioPriceSliceConfigs,
  SliceConfig,
} from './offchainTrading.ruleset';
import {
  OwnedOffchainToken,
  PriceSlice,
  PriceTrends,
} from '../game/player.schema';
import { isNextDay, isSameDay } from '../../utils/time';
import Big from 'big.js';
import { stage } from '../game/game.config';
import { isFromTiktokOnwards as getIsFromTiktokOnwards } from '../game/game.getters';
import { tests } from '../../ruleset';
import { getFeatureAb } from '../game/abtest.getters';

type API =
  | ReplicantAsyncActionAPI<ReplicantServer>
  | ReplicantEventHandlerAPI<ReplicantServer>
  | ReplicantSyncActionAPI<ReplicantServer>;

function addPriceToTrend(
  trend: PriceSlice[],
  sliceConfig: SliceConfig,
  price: string,
  time: number,
) {
  let firstPrice = price;
  while (trend.length > 0) {
    firstPrice = trend[0].price;
    if (trend[0].time >= time - sliceConfig.window) {
      break;
    }

    // slice is out of the time window
    // time to kick some butts
    trend.shift();
  }

  const windowStartTime =
    sliceConfig.interval *
    Math.floor((time - sliceConfig.window) / sliceConfig.interval);
  if (trend.length === 0 || trend[0].time !== windowStartTime) {
    trend.unshift({
      time: windowStartTime,
      price: firstPrice,
    });
  }

  // setting the price for the NEXT price slice
  // the reason is that the last transaction of a slice sets the entry price of the price slice coming after it
  const sliceTime =
    sliceConfig.interval * Math.floor(time / sliceConfig.interval);
  const nextSliceTime = sliceTime + sliceConfig.interval;
  if (trend[trend.length - 1].time < nextSliceTime) {
    // create next slice
    trend.push({
      time: nextSliceTime,
      price,
    });
  } else if (trend[trend.length - 1].time < sliceTime) {
    // last slice is already the next slice
    trend[trend.length - 1].price = price;
  } else {
    // ignore price point
  }
}

function addPriceToTrends(trends: PriceTrends, price: string, time: number) {
  for (let i = 0; i < fixedSliceTimeWindows.length; i += 1) {
    const timeWindow = fixedSliceTimeWindows[i];
    const sliceConfig =
      portfolioPriceSliceConfigs[timeWindow as FixedSliceTimeWindows];
    const trend = trends[timeWindow as FixedSliceTimeWindows];
    addPriceToTrend(trend, sliceConfig, price, time);
  }

  // update slices for the allTime/variable time window
  const allTimeTrend = trends.allTime;
  if (
    allTimeTrend.length === 0 ||
    allTimeTrend[allTimeTrend.length - 1].time < time
  ) {
    allTimeTrend.push({ time, price });
  }

  // @note: only one iteration of the loop should happen
  while (allTimeTrend.length > maxPortfolioAllTimeSliceCount) {
    const timeWindow =
      allTimeTrend[allTimeTrend.length - 1].time - allTimeTrend[0].time;

    let smallestInterval = timeWindow;
    let smallestIntervalSliceIdx = -1;
    let previousTime = allTimeTrend[0].time;
    for (let i = 1; i < allTimeTrend.length - 1; i += 1) {
      const slice = allTimeTrend[i];

      // @todo?
      // prioritize merges of distance point in times by multipltying interval by a distance coefficient,
      // it virtually makes distant intervals smaller
      // const distanceCoeff = Math.pow(i / allTimeTrend.length, 0.5);
      // const interval = distanceCoeff * (slice.time - previousTime);

      const interval = slice.time - previousTime;
      if (interval < smallestInterval) {
        smallestInterval = interval;
        smallestIntervalSliceIdx = i;
      }
      previousTime = slice.time;
    }

    trends.allTime.splice(smallestIntervalSliceIdx, 1);
  }
}

export const savePortfolioPricePoint = async (
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) => {
  const now = api.date.now();
  const portfolioValue = (await getPortfolioValue(state, api)).toString();

  addPriceToTrends(state.trading.offchain.portfolioTrends, portfolioValue, now);
};

export const tmgInitState = (state: MutableState, tokenId: string) => {
  if (!state.trading.miniGames.state[tokenId]) {
    const tappingMaxTickets = getTMGTappingMaxTickets(state);
    state.trading.miniGames.state[tokenId] = {
      ...tmgRuleset.initialTokenState,
      tickets: tappingMaxTickets,
    };
  }
};

export const tmgStartTokenFarming = (
  state: MutableState,
  { tokenId }: { tokenId: string },
  now: number,
) => {
  if (
    getTTGFarmingSpotsAvailable(state) <= 0 ||
    getTTGIsFarming(state, tokenId)
  ) {
    return false;
  }
  tmgInitState(state, tokenId);
  state.trading.miniGames.state[tokenId].miningStart = now;
  return true;
};

const tmgAddDailyTap = (
  state: MutableState,
  { tokenId, taps = 1, now }: { tokenId: string; taps?: number; now: number },
) => {
  tmgInitState(state, tokenId);
  const sameDay = isSameDay(getTmgTicketTimestamp(state), now);
  if (sameDay) {
    state.trading.miniGames.state[tokenId].dailyScore += taps;
  }
};

// !IMPORTANT @TODO: Update logic for farming
export const tmgClaimFarmingReward = (
  state: MutableState,
  { tokenId }: { tokenId: string },
  now: number,
) => {
  if (!getTTGCanClaim(state, tokenId, now)) {
    return -1;
  }
  // give reward
  const reward = getTTGFarmingPoints(state, tokenId, now);
  // tmgAddPoint(state, { tokenId, points: reward });
  // reset farming state
  state.trading.miniGames.state[tokenId].miningStart = undefined;
  return reward;
};

// @CAI
export const tmgConsumeTapTicket = (
  state: MutableState,
  api: API,
  tokenId: string,
) => {
  const useGlobalTickets = getFeatureAb(
    'TEST_GLOBAL_MEME_TICKETS',
    api.abTests,
  );

  if (useGlobalTickets) {
    if (state.trading.miniGames.tapping.tickets <= 0) {
      return false;
    }

    state.trading.miniGames.tapping.tickets--;
    return true;
  }

  if (tokenId) {
    tmgInitState(state, tokenId);

    if (state.trading.miniGames.state[tokenId].tickets <= 0) {
      return false;
    }

    state.trading.miniGames.state[tokenId].tickets--;
    return true;
  }

  return false;
};

export const startGameSession = (
  state: MutableState,
  api: API,
  tokenId: string,
) => {
  tmgInitState(state, tokenId);
  state.trading.miniGames.tapping.sessionTaps = 0;
  return tmgConsumeTapTicket(state, api, tokenId);
};

export const tmgRefillTicketsAndWipeSessionCache = (
  state: MutableState,
  api: API,
  fromCheat?: 'useCheat' | 'cheatResetDaily',
) => {
  const now = api.date.now();
  // Reset session cache
  state.trading.miniGames.tapping.sessionTaps = 0;

  const isNotCheat = !Boolean(fromCheat);

  const hasNotBeen1ServerDay = !isNextDay(getTmgTicketTimestamp(state), now);

  const notReadyToRefresh = hasNotBeen1ServerDay && isNotCheat;

  if (notReadyToRefresh) {
    return;
  }

  const resetDaily = isNotCheat || fromCheat === 'cheatResetDaily';

  const tappingMaxTickets = getTMGTappingMaxTickets(state);

  const useGlobalTickets = getFeatureAb(
    'TEST_GLOBAL_MEME_TICKETS',
    api.abTests,
  );

  if (useGlobalTickets) {
    state.trading.miniGames.tapping.tickets = tmgRuleset.tappingMaxTickets;
  } else {
    Object.values(state.trading.miniGames.state).forEach((token) => {
      if (token.tickets < tappingMaxTickets) {
        token.tickets = tappingMaxTickets;
      }
      // Reset the daily score
      if (resetDaily) {
        token.dailyScore = 0;
      }
    });
  }

  state.trading.miniGames.tapping.ticketTimestamp = now;
};

export const tmgSyncTapsOnSessionComplete = (
  state: MutableState,
  tokenId: string,
  now: number,
) => {
  const taps = Math.min(
    state.trading.miniGames.tapping.sessionTaps,
    tmgRuleset.tappingMaxScore,
  );
  if (taps <= 0) {
    return 0;
  }
  state.trading.miniGames.tapping.sessionTaps = 0;
  tmgAddDailyTap(state, { tokenId, taps, now });
  return taps;
};

export type TokenUpdate = {
  tokenAmount: string;
  currencyInvested: string;
  lastNotifPrice: string;
  pointsAccumulated: string;
};

export const convertScore = async (
  state: State,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
  {
    tokenId,
    score,
  }: {
    tokenId: string;
    score: number;
  },
): Promise<TokenUpdate> => {
  const timestamp = getActualTime(api);

  const tokenHolding = state.trading.offchainTokens[tokenId];

  const buyerId = state.id;
  const buyerName = state.profile.name;
  const buyerImage = state.profile.photo;
  const pointsAccumulated =
    tokenHolding?.pointsAccumulated ?? Big(0).toString();
  const tokenAmountHeldBeforeTx =
    tokenHolding?.tokenAmount ?? Big(0).toString();

  const offchainTokenState = await api.sharedStates.offchainTrading.fetch(
    tokenId,
  );
  const offchainToken = offchainTokenState?.global;
  if (!offchainToken) {
    throw new Error(
      `Attempting to convert score for meme that doesn't exist '${tokenId}'.`,
    );
  }

  const isNewHolder = !tokenHolding || Big(tokenHolding.tokenAmount).eq(0);

  if (stage !== 'prod') {
    api.sendAnalyticsEvents([
      {
        eventType: 'DebugConvertScore1',
        eventProperties: {
          timestamp,
          buyerId,
          buyerName,
          buyerImage,
          score,
          pointsAccumulated,
          tokenAmountHeldBeforeTx,
          expectedTxIdx: offchainToken.txs.length,
          isNewHolder,
        },
      },
    ]);
  }

  // We should probably do the R1 and R2 in the same message if we can!!!
  api.sharedStates.offchainTrading.postMessage.exchangeScoreForPoints(tokenId, {
    timestamp,
    buyerId,
    buyerName,
    buyerImage,
    score,
    pointsAccumulated,
    tokenAmountHeldBeforeTx,
    expectedTxIdx: offchainToken.txs.length,
    isNewHolder,
  });

  const txConfirmation = await confirmTransaction(
    api as ReplicantAsyncActionAPI<ReplicantServer>,
    buyerId,
    tokenId,
    timestamp,
  );

  if (!txConfirmation) {
    throw new Error(`Failed to confirm score conversion`);
  }

  const transaction = txConfirmation.transaction;
  const txTokenAmount = transaction.tokenAmount;

  const supply = offchainToken.supply;
  const lastNotifPrice = getCurvePrice(
    Big(supply).add(txTokenAmount),
  ).toString();

  const currencyAmountBig = Big(transaction.currencyAmount);
  const currencyInvested = currencyAmountBig.round();

  return {
    tokenAmount: txTokenAmount,
    pointsAccumulated: txTokenAmount,
    currencyInvested: currencyInvested.toString(),
    lastNotifPrice: lastNotifPrice,
  };
};

export function updateTokenHolding(
  state: MutableState,
  tokenId: string,
  tokenUpdate: OwnedOffchainToken,
  priceKnownByUser: boolean = false,
) {
  const ownedToken = state.trading.offchainTokens[tokenId];

  if (!ownedToken) {
    state.trading.offchainTokens[tokenId] = tokenUpdate;
  } else {
    const { tokenAmount, pointsAccumulated, currencyInvested, lastNotifPrice } =
      tokenUpdate;

    ownedToken.tokenAmount = Big(ownedToken.tokenAmount || '0')
      .add(tokenAmount)
      .toString();

    ownedToken.currencyInvested = Big(ownedToken.currencyInvested || '0')
      .add(currencyInvested)
      .toString();

    if (getIsFromTiktokOnwards()) {
      ownedToken.pointsAccumulated = Big(ownedToken.pointsAccumulated || '0')
        .add(pointsAccumulated)
        .toString();
    }

    if (!ownedToken.lastNotifPrice || priceKnownByUser) {
      ownedToken.lastNotifPrice = lastNotifPrice;
    }
  }

  state.trading.offchain.currencySpent = Big(
    state.trading.offchain.currencySpent,
  )
    .add(tokenUpdate.currencyInvested)
    .toString();
}

export function grantTMGTappingTickets(
  state: MutableState,
  api: API,
  amount = 1,
  tokenId?: string,
) {
  const useGlobalTickets = getFeatureAb(
    'TEST_GLOBAL_MEME_TICKETS',
    api.abTests,
  );

  if (useGlobalTickets) {
    state.trading.miniGames.tapping.tickets += amount;
  } else {
    if (!tokenId) {
      console.error(`Cannot grant 'tickets' without 'tokenId'`);
      return;
    }
    state.trading.miniGames.state[tokenId].tickets += amount;
  }
}
