import './JoinTeam.scss';
import { assets } from '../../../assets/assets';
import { CREATE_TEAM_COST } from '../../../replicant/features/teams/teams.ruleset';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../replicant/utils/numbers';
import { app } from '../../../data/Controllers/AppController';
import { invite } from '../../../data/sharing';
import { getBotName } from '../../../data/config';

export const CreateTeamDetails = () => {
  const { t } = useTranslation();

  const canAffordCreate = app.playerBalance >= CREATE_TEAM_COST;

  const onCreateTeamClick = () => {
    app.ui.drawer.close();

    if (canAffordCreate) {
      invite({
        screen_location: 'create_team', // create_team_details
        url: `https://t.me/${getBotName()}?start=create_team`,
        data: {
          feature: 'team',
          $subFeature: 'team_create',
        },
      });

      setTimeout(() => {
        Telegram.WebApp.close();
      });
    }
  };

  return (
    <div className="createTeam-details">
      <div className="createTeam-details-header">
        <div className="createTeam-details-icon">
          <img src={assets.sloth_createteam} />
        </div>
        <div className="createTeam-details-title">{t('team_create_title')}</div>
        <div className="createTeam-details-subtitle">
          {t('team_create_subtitle')}
        </div>
      </div>

      <div className="createTeam-details-body">
        <div className="createTeam-details-body-value-icon">
          <img src={assets.coin} />
        </div>
        <div className="createTeam-details-body-value-label">
          {formatPrice(CREATE_TEAM_COST)}
        </div>
        <div className="createTeam-details-body-perteam">
          / {t('team_create_per_team')}
        </div>
      </div>

      <div className="footer-buttons">
        <div
          className={`btn btn-normal ${!canAffordCreate && 'disabled'}`}
          onClick={onCreateTeamClick}
        >
          {t('team_create_button')}
        </div>
      </div>
    </div>
  );
};
